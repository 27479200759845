import React, {
	useState,
	useEffect,
	useImperativeHandle,
	forwardRef,
	useRef
} from "react";
import { TFUAutoCompleteProps, DropdownModel } from "./PropsDefined";
import { CONSTANTS } from "./Constants";
import BaseServices from "./services/BaseServices";

const initValidate = { isValid: true, invalidMsg: "" };
/**
 * tfu autocomplete
 * @param {TFUAutoCompleteProps} props props
 */
function TFUAutoComplete(props, ref) {
	const [showDropdown, setShowDropdown] = useState(false);
	const [displayText, setText] = useState("");
	const [items, setItems] = useState([]);
	const [validate, setValidate] = useState(initValidate);
	const inputRef = useRef(null);

	useImperativeHandle(ref, () => ({
		displayError: _displayError,
		validateInput: _validateInput,
		props,
	}));

	useEffect(() => {
		_fetchData();
	}, [props.urlFetchData]);

	async function _fetchData() {
		if (props.urlFetchData.params) {
			const { url, params } = props.urlFetchData;
			let [error, data] = await BaseServices.Post(url, params);
			if (!error) {
				let options = data.listObjects.map((item) => ({
					value: item.id,
					text: item.name,
				}));
				setItems(options);
				if (props.value) {
					let item = options.find(x => x.value == props.value)
					if (item) setText(item.text)
				}
			} else {
				setItems([]);
			}
		}
		if (props.urlFetchData) {
			let [error, data] = await BaseServices.Get(props.urlFetchData);
			if (!error) {
				setItems(data);
			} else {
				setItems([]);
			}
		} else if (props.items) {
			setItems(props.items);
		}
	}

	function _handleTextChange(e) {
		setShowDropdown(true);
		let text = e.target.value;
		setText(text);
		props.onSelectedItemChanged({
			value: "0",
			label: text,
			text: text,
			isTyping: true,
		});
		if (props.required)
			setValidate({ isValid: false, invalidMsg: CONSTANTS.MSG_REQUIRED });
	}

	/**
	 * handle select item
	 * @param {DropdownModel} item dropdown model item
	 */
	function _handleSelectItem(item) {

		setText(item.text);
		if (props.onSelectedItemChanged && props.value != item.value) {
			props.onSelectedItemChanged({ ...item, isTyping: false });
		}
		setShowDropdown(false);
		setValidate({ isValid: true, invalidMsg: "" });
	}

	function _handleRemoveSelected() {
		setShowDropdown(true);
		setText("");
		props.onSelectedItemChanged({
			value: "0",
			label: "",
			text: "",
			isTyping: false,
		});
		if (props.required)
			setValidate({ isValid: false, invalidMsg: CONSTANTS.MSG_REQUIRED });
	}

	function _renderDropdown() {
		if (!items) return null;
		if (items.length == 0)
			items.push({ value: "", text: "Không có dữ liệu" });
		return (
			<div className="tfu-autocomplete-items">
				{items.map((item, index) => (
					<div
						className={
							"tfu-autocomplete-item " +
							(item.value && "tfu-autocomplete-item-has-value ") +
							(item.value &&
								item.value == props.value &&
								"tfu-autocomplete-item-selected ")
						}
						key={index}
						onClick={() => {
							if (item.value) _handleSelectItem(item);
						}}
					>
						<span>{item.text}</span>
					</div>
				))}
			</div>
		);
	}

	function _handleShowDropdown() {
		if (!showDropdown) inputRef.current.focus();
		setShowDropdown(!showDropdown);
	}

	function _handleClickOutside() {
		setTimeout(function () {
			setShowDropdown(false);
		}, 200);
	}

	const _displayError = (msg) => {
		setValidate({ isValid: false, invalidMsg: msg });
	};

	const _validateInput = (value) => {
		if (props.required && !value) return CONSTANTS.MSG_REQUIRED;
		return "";
	};

	return (
		/**
		 * have to improve:
		 * ***position of dropdown list: top/bottom
		 * ***Max heigth, auto scroll inside dropdown list
		 * ***Can use arrow key to move between line item: up/down arrow keys
		 */
		<div
			className={
				"tfu-autocomplete " + (props.sm && "tfu-autocomplete-sm")
			}
			onBlur={_handleClickOutside}
		>
			<input
				aria-autocomplete={false}
				className="tfu-autocomplete-input"
				id={props.id}
				name={props.name}
				ref={inputRef}
				value={displayText}
				onChange={_handleTextChange}
				onFocus={() => {
					_fetchData();
					setShowDropdown(true);
				}}
				onBlur={_handleClickOutside}
				disabled={props.disabled}
				autoComplete={"off"}
			/>
			{!displayText || props.disabled ? (
				props.placeholder ? (
					<label
						className="tfu-autocomplete-placeholder"
						onClick={_handleShowDropdown}
					>
						{props.placeholder}
					</label>
				) : (
					""
				)
			) : (
				<span
					className="tfu-autocomplete-close fa fa-close"
					onClick={_handleRemoveSelected}
				/>
			)}
			{!props.disabled && <span
				className={`tfu-autocomplete-chevron fa fa-chevron-${showDropdown ? "up" : "down"}`}
				onClick={_handleShowDropdown}
			/>}
			{showDropdown && _renderDropdown()}
			{validate && !validate.isValid && (
				<label className="text-danger full-width" htmlFor={props.id}>
					{validate.invalidMsg}
				</label>
			)}
		</div>
	);
}

export default forwardRef(TFUAutoComplete);
