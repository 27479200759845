import React, { useRef } from "react";

function DragAndDropIndex({data, renderRow , updateIndexFolder , renderIsDown , isRunIndex = false}) {

    const handleDragStart = (e, id) => {
        e.dataTransfer.setData("text/plain", id);
    };
    const handleDragEnd = (e) => {
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e, targetIdx) => {
        e.preventDefault();
        const sourceId = e.dataTransfer.getData("text/plain");
        updateIndexFolder(sourceId,targetIdx)//id của item và vị trí muốn di chuyển đến
        
    };

    return (
        data && data.length > 0 ?
        <tbody>
            {data.map((item, i) => {
                return (
                    <React.Fragment>
                        <tr key={i}
                            draggable={item.id > 0 ? "true" : "false" }
                            onDragStart={(e) => handleDragStart(e, item.id)}
                            onDragEnd={(e) => handleDragEnd(e)}
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => item.id > 0 ? handleDrop(e, isRunIndex ? item.runIndex : item.index) : ""}
                            style={{cursor: 'move'}}>
                            {renderRow(item, i)}
                        </tr>
                        {renderIsDown ? renderIsDown(item, i) : ""}
                    </React.Fragment>
                    

                )
            })}
            
        </tbody>
        : 
        <tbody>
            <tr>
                <td colSpan={999} className="text-center">
                    Không có dữ liệu!
                </td>
            </tr>
        </tbody>
        
        
    )
}

export default DragAndDropIndex;
