import BaseServices from "../../based/services/BaseServices";

const PushingServices = {
    GetPhotoshopFileByPage: async (page) => {
        return BaseServices.Post("/api/pushProduct/get-photoshop-file-by-page", page);
    },
    DeletePhotoshopFile: async (id) => {
        return BaseServices.Get("/api/pushProduct/delete-photoshop-file/" + id);
    },
    GetPhotoshopFileById: async (id) => {
        return BaseServices.Get("/api/pushProduct/get-photoshop-file-by-id/" + id);
    },
    CreateOrUpdatePhotoshopFile: async (model) => {
        return BaseServices.Post("/api/pushProduct/create-or-update-photoshop-file", model);
    },
    GetDropdownProduct: async () => {
        return BaseServices.Get("/api/pushProduct/get-dropdown-product");
    },
    GetPushingProductModels: async (page) => {
        return BaseServices.Post("/api/pushProduct/get-pushing-product-models", page);
    },
    GetShopByPlatform: async (platform) => {
        return BaseServices.Get("/api/pushProduct/get-shop-by-platform/" + platform);
    },
    GetPushingPhotoshopProduct: async (photoshopId) => {
        return BaseServices.Get("/api/pushProduct/get-pushing-photoshop-product/" + photoshopId);
    },
    GetProductCustomizesById: async (designId, platform) => {
        return BaseServices.Get(`/api/pushProduct/get-product-customizes-by-id/${designId}/${platform}`);
    },
    GetPushingPhotoshopProductDetail: async (designId, photoshopId) => {
        return BaseServices.Get(`/api/pushProduct/get-pushing-photoshop-product-detail/${designId}/${photoshopId}`);
    },
    CreateUpdatePushProduct: async (model) => {
        return BaseServices.Post("/api/pushProduct/create-update-push-product", model);
    },
    GetColorValue: async () => {
        return BaseServices.Get("/api/pushProduct/get-color-value");
    },
    SetStatusPushCustomize: async (pushProductId, isTopPick) => {
        return BaseServices.Get(`/api/pushProduct/set-status-push-customize/${pushProductId}/${isTopPick}`);
    },
    GetPushingProductTotalModels: async (page) => {
        return BaseServices.Post("/api/pushProduct/get-pushing-product-total-models", page);
    },
    GetProductCustomizesMultiById: async (designId) => {
        return BaseServices.Get(`/api/pushProduct/get-product-customizes-multi-by-id/${designId}`);
    },
    CreateUpdatePushProductMulti: async (model) => {
        return BaseServices.Post("/api/pushProduct/create-update-push-multi", model);
    },
};

export default PushingServices;
