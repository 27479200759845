import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Textbox from "../based/inputs/Textbox";
import { ProductVariantContext } from "../context/ProductVariantContext";
import { PackageType } from "../models/BaseProductModel";
import SectionIgnorePixel from "./SectionIgnorePixel";
import SectionVariantFrameConfig from "./SectionVariantFrameConfig";
import Request from "../based/Request";
import { Notify } from "../based/Notify";
import { NOTIFY } from "../based/Constants";

import styled from "styled-components";

export default function SectionBasicInfo(props) {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);
    const [isBusy, setIsBusy] = useState(false);

    const handleFileUpload = (e, idx) => {
        setIsBusy(true);
        e.preventDefault();
        let file = e.target.files[0];
        if (!file) {
            setIsBusy(false);
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.UploadPhotoshop(file).then((res) => {
                if (res && res.data) {
                    const { fileUrl, fileName } = res.data;
                    let newPhotoshops = [...editingVariant.productVariantPhotoshops];
                    let item = newPhotoshops[idx];
                    if (item) {
                        item.photoshopUrl = fileUrl;
                    }

                    setEditingVariant({ ...editingVariant, productVariantPhotoshops: newPhotoshops });
                    Notify(NOTIFY.SUCCESS, "Upload file thành công", `File ${fileName} đã được upload thành công`);
                } else {
                    Notify(NOTIFY.ERROR, "Upload file thất bại", "Có lỗi xảy ra khi upload file");
                }
            });
        };
    };

    const handleImageChange = (e, idx) => {
        setIsBusy(true);

        let file = e.target.files[0];
        if (!file) {
            setIsBusy(false);
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.UploadImage(file).then((res) => {
                if (res && res.data) {
                    let { imageUrl } = res.data;
                    let newPhotoshops = [...editingVariant.productVariantPhotoshops];
                    let item = newPhotoshops[idx];
                    if (item) {
                        item.thumbnail = imageUrl;
                    }

                    setEditingVariant({ ...editingVariant, productVariantPhotoshops: newPhotoshops });
                }
            });
        };

        setIsBusy(false);
    };

    return (
        <div className="section-productVariants-basicinfo col-md-12 card-box p-3 section-description">
            <h4 className="header-title m-t-0">
                <b>Thông tin cơ bản của biến thể</b>
            </h4>
            <p className="sub-header">
                Notes:
                <br />
                <span>
                    - Config thông tin khung in tại{" "}
                    <Link className="text-custom text-bold" to="/attribute-management">
                        Size (Kích thước - Thuộc tính)
                    </Link>
                    trước khi config từng biến thể
                </span>
                <br />- Hệ thống sẽ lấy <b>Size Tiêu Chuẩn</b> và <b>Checkbox "Sử dụng hình ảnh"</b> để tiến hành tạo mockup
                <br />- Hệ thống sẽ tính toán dựa vào tọa độ <b>điểm số 1 (Dưới cổ)</b>, <b>điểm số 2</b> cách cổ <b>35cm</b>.
                <br />- Template hỗ trợ upload hình ảnh: <b>1A_Front_15_25_35</b>. VD: Bộ ảnh 1A, Mặt trước, vị trí điểm số 1 trục X, vị trí điểm số 1 trục Y, vị trí điểm số 2 trục Y
                <br />- Template hỗ trợ upload logo: <b>1A_Left_15_25_35</b>. VD: Bộ ảnh 1A, Tay trái, vị trí điểm số 1 góc X, vị trí điểm số 1 góc Y, vị trí điểm số 2 góc Y
                <br />- <b>Cần config tất cả các mặt (Mặt trước, mặt sau, trái, phải) để có thể xác định được logo</b>
            </p>
            <div className="row">
                <div className="col-md-12">
                    <label htmlFor="variantName" className="col-form-label">
                        Tên biến thể
                    </label>
                    <Textbox key={`variantName[${editingVariant.id}]`} id="variantName" name="variantName" value={editingVariant.name} onChanged={(name, value) => setEditingVariant({ ...editingVariant, name: value, isModified: true })} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <label htmlFor="uploadVariantImages" className="col-form-label full-width">
                        Hình ảnh của biến thể và logo (Mặt trước, mặt sau)
                    </label>
                    <SectionVariantFrameConfig />
                </div>

                {editingVariant.packageType == PackageType.Case && (
                    <div className="col-md-12">
                        <label htmlFor="uploadVariantImages" className="col-form-label full-width">
                            Hình ảnh của vị trí ignore pixel Ốp lưng
                        </label>
                        <SectionIgnorePixel />
                    </div>
                )}
            </div>

            <div className="card-box margin-bottom-10">
                <div className="button-action">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            let newPhotoshops = editingVariant.productVariantPhotoshops || [];
                            newPhotoshops.push({
                                thumbnail: "",
                                photoshopUrl: "",
                                appProductVariantId: editingVariant.id,
                            });
                            setEditingVariant({ ...editingVariant, productVariantPhotoshops: newPhotoshops });
                        }}
                    >
                        Thêm file
                    </button>
                </div>
                <table className="table table-bordered m-b-0">
                    <thead className="thead-light">
                        <th className="cursor-pointer center">Thumbnail</th>
                        <th className="cursor-pointer center">Link File</th>
                        <th></th>
                    </thead>
                    <tbody>
                        {editingVariant.productVariantPhotoshops &&
                            editingVariant.productVariantPhotoshops.map((item, index) => (
                                <tr key={index}>
                                    <td className="center">
                                        {item.thumbnail ? (
                                            <img
                                                src={item.thumbnail}
                                                alt="thumbnail"
                                                onClick={() => {
                                                    let element = document.getElementById(`upload-thumbnail-${index}`);
                                                    if (element) {
                                                        element.click();
                                                    }
                                                }}
                                                style={{ cursor: "pointer", width: "100px", height: "100px", objectFit: "contain" }}
                                            />
                                        ) : (
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    let element = document.getElementById(`upload-thumbnail-${index}`);
                                                    if (element) {
                                                        element.click();
                                                    }
                                                }}
                                            >
                                                Upload thumbnail
                                            </button>
                                        )}
                                        <input
                                            type="file"
                                            className="d-none"
                                            name={`upload-thumbnail-${index}`}
                                            id={`upload-thumbnail-${index}`}
                                            onChange={(e) => {
                                                handleImageChange(e, index);
                                            }}
                                        />
                                    </td>
                                    <td className="center">
                                        {item.photoshopUrl ? (
                                            <React.Fragment>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        let element = document.getElementById(`upload-photoshop-${index}`);
                                                        if (element) {
                                                            element.click();
                                                        }
                                                    }}
                                                >
                                                    Upload file
                                                </button>
                                                <a href={item.photoshopUrl} target="_blank" className="d-block">
                                                    {item.photoshopUrl}
                                                </a>
                                            </React.Fragment>
                                        ) : (
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    let element = document.getElementById(`upload-photoshop-${index}`);
                                                    if (element) {
                                                        element.click();
                                                    }
                                                }}
                                            >
                                                Upload file
                                            </button>
                                        )}

                                        <input
                                            type="file"
                                            className="d-none"
                                            name={`upload-photoshop-${index}`}
                                            id={`upload-photoshop-${index}`}
                                            onChange={(e) => {
                                                handleFileUpload(e, index);
                                            }}
                                        />
                                    </td>
                                    <td className="center">
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                let newPhotoshops = [...editingVariant.productVariantPhotoshops];
                                                newPhotoshops.splice(index, 1);
                                                setEditingVariant({ ...editingVariant, productVariantPhotoshops: newPhotoshops });
                                            }}
                                        >
                                            Xóa
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const Wrapper = styled.div``;
