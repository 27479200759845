import React from "react";
import {useState, useEffect } from "react";
import { NOTIFY } from "../based/Constants";
import PropTypes from "prop-types";
import Textbox from "../based/inputs/Textbox";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import useFormValidate from "../hooks/useFormValidate";
import Common from "../based/Common";
import { INITPAGING , CONSTANTS} from "../based/Constants";
import styled from "styled-components";
import CheckBox from "../based/inputs/CheckBox";


const ModalAddProduct = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState("");
    const [products, setProducts] = useState([]);
    const { addRef, displayError, isValid } = useFormValidate();

    useEffect(()=>{
        
    },[])

    

   
    const _handleSave = async () => {
        var ids = products.filter((x) => x.isChecked).map((x) => x.id);

        if (ids && ids.length > 0) {
            setIsBusy(true);
            let [err, data] = await FolderProductPrototypeServices.CreateFolderConnectionAppProduct(ids , props.folderId);
            if (!err) {
                if(data){
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
                }else{
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Đã tồn tại");

                }
                setIsBusy(false);
                setProducts([]);

            } else {
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            props.onSaveContinue();
        }else{
            Notify(NOTIFY.WARNING, NOTIFY.WARNING, "Chưa chọn sản phẩm nào");
        }
       
    };
    const _handleChecked = (index, value) => {
        let newDatas = [...products];
        if (index === -1) newDatas.forEach((m) => (m.isChecked = value));
        else newDatas[index].isChecked = value;
        setProducts(newDatas);
    };
    const renderTable= ()=>{
        return products && products.length> 0 && products.map((item,idx)=>{
            return <tr key={idx}>
                <td style={{width: 50}}>
                <CheckBox id={idx.toString()} name={idx.toString()} checked={item.isChecked ? item.isChecked : false} label=' ' onCheckedChange={(name, value) => _handleChecked(idx, value)} />
                </td>
                <td style={{width: 100}}>
                    {item.productAvatar ? <img src={item.productAvatar}/> : <img />}
                </td>
                <td>
                    {item.name}
                </td>
            </tr>
        })
    }
    const handleChangeText = async(e)=>{
        if(e.charCode === 13){
            let [err, data] = await FolderProductPrototypeServices.GetAppProductByKeyword(e.target.value);
            if (!err && data) {
                setProducts(data);
            } else {
                setProducts([]);
            }
        }
    }

    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Thêm sản phẩm"} onSave={_handleSave} onClose={() => props.onClose()}>
            <Wapper>
                <input type="text" placeholder="Nhập tên sản phẩm" onKeyPress={(e)=>{handleChangeText(e)}} />
                <table>
                    <tbody>
                    {renderTable()}
                    </tbody>
                </table>
            </Wapper>
        </CommonModal>
    );
};

export default ModalAddProduct;
const Wapper = styled.div`
    img{
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: contain;
    }

    input{
        width: 100%;
        height: 30px;

    }
    table{
        margin-top: 10px;
        width: 100%;

        tbody{
            tr{
                width: 100%;
            }
            td{
                padding: 10px;
                border: 1px solid #ccc;
            }
        }
    }
    
`
ModalAddProduct.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
