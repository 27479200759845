import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import Breadcrumbs from '../based/Breadcrumbs';
import Common from '../based/Common';
import Loading from '../based/Loading';
import { Layout } from '../layout/Layout';
import ManageAgencyProduct from '../manage-agency-product/ManageAgencyProduct';
import ProductsListing from './ProductsListing';
export default function ManageProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    return !Common.IsAdmin() ? (
        <ManageAgencyProduct history={props.history} />
    ) : (
        <React.Fragment>
            <Breadcrumbs currentPage='Sản phẩm' />
            <Loading show={isLoading} msg='Đang tải dữ liệu...' />
            <Route path='/' component={ProductsListing} />
        </React.Fragment>
    );
}
