import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import TFUImage from "../based/inputs/Image";
import MultipleImages from "../based/inputs/MultipleImages";
import Textbox from "../based/inputs/Textbox";
import TextNumber from "../based/inputs/TextNumber";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import TFUSelect from "../based/refactor/TFUSelect";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import useFormValidate from "../hooks/useFormValidate";

const ModalUpdateProduct = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState("");
    const [products, setProducts] = useState({});
    const { addRef, displayError, isValid } = useFormValidate();
    const [imgShow, setImageShow] = useState([]);
    const [listColor, setListColor] = useState([]);
    const [listSeoImage, setListSeoImage] = useState([]);

    useEffect(() => {
        setProducts({
            idProduct: props.item.id,
            name: props.item.nameShow,
            price: props.item.price,
            index: props.item.index,
        });
        setListSeoImage(props.item.listSeoImage);
        getImageShow();
        getColor();
    }, []);

    const getImageShow = async () => {
        let [err, data] = await FolderProductPrototypeServices.GetImageShowByProductId(props.item.id);
        if (!err && data) {
            setImageShow(data);
        } else {
            setImageShow([]);
        }
    };

    const getColor = async () => {
        let [err, data] = await FolderProductPrototypeServices.GetAttributeValueColorByAppProductId(props.item.id);
        if (!err && data) {
            setListColor(data);
        } else {
            setListColor([]);
        }
    };

    const _handleSave = async () => {
        let imgs = [...imgShow];
        let urls = imgs.map((img) => img.url);
        console.log(urls);
        if (isValid()) {
            setIsBusy(true);
            let [err, data] = await FolderProductPrototypeServices.UpdateFolderConnectionAppProduct({
                model: { ...products, listSeoImage: listSeoImage },
                urls,
            });
            if (!err && data) {
                setIsBusy(false);
                setProducts({});
                setImageShow([]);
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            } else {
                setIsBusy(false);
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Lưu thất bại");
            }
            props.onSaveContinue();
        }
    };

    const _handleImageChange = (value) => {
        let data = [];
        let dt = [...imgShow];
        if (value && value.length > 0) {
            value.map((img) => {
                var existed = dt.find((x) => x.url == img);
                if (!existed) {
                    var item = {
                        id: 0,
                        idProduct: props.item.id,
                        url: img,
                    };
                    data.push(item);
                }
            });
        }
        setImageShow([...dt, ...data]);
    };
    function handleRemoveImage(item) {
        let data = imgShow.filter((x) => x.url !== item.url);
        setImageShow([...data]);
    }
    return (
        <CommonModal show={props.isShowModal} id={Common.generateGuid()} isBusy={isBusy} busyMsg={msg} title={"Cập nhật sản phẩm"} onSave={_handleSave} onClose={() => props.onClose()}>
            <Wapper>
                <div>{props.item.name}</div>
                <div className="box-input">
                    <div className="w50">
                        <label htmlFor="name">Tên hiển thị</label>
                        <Textbox
                            id="name"
                            name="name"
                            required
                            ref={addRef}
                            className="form-control"
                            onChanged={(name, value) => {
                                setProducts({ ...products, name: value });
                            }}
                            value={products.name}
                        />
                    </div>
                    <div className="w50 pl-2">
                        <label htmlFor="price">Giá</label>
                        <TextNumber
                            id="price"
                            name="price"
                            required
                            ref={addRef}
                            className="form-control"
                            onChanged={(name, value) => {
                                setProducts({ ...products, price: value });
                            }}
                            value={products.price}
                        />
                    </div>
                </div>
                <div>
                    <div className="box-title mt-2">
                        <span>Hình thực tế</span>
                        <label className="text-add" htmlFor="choose-img">
                            Thêm +
                        </label>
                    </div>
                    <MultipleImages className="hide" id="choose-img" name="" images={[]} onChanged={(key, value) => _handleImageChange(value)} />
                    <div className="group-img">
                        {imgShow &&
                            imgShow.length > 0 &&
                            imgShow.map((item, index) => {
                                return (
                                    <div key={index} className="col-img">
                                        <img src={item.url} />
                                        <div
                                            className="remove"
                                            onClick={() => {
                                                handleRemoveImage(item);
                                            }}
                                        >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.1392 0.685547L1.14844 10.6763" stroke="#D70000" />
                                                <path d="M11.1387 10.6763L1.14793 0.685546" stroke="#D70000" />
                                            </svg>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <hr />
                <div className="box-seo">
                    <div className="box-title mt-2">
                        <span>Thông tin SEO</span>
                        <label
                            className="text-add"
                            onClick={() => {
                                let lstSeoImage = [...listSeoImage];
                                lstSeoImage.push({
                                    seoColor: "",
                                    seoImage: "",
                                });
                                setListSeoImage(lstSeoImage);
                            }}
                        >
                            Thêm +
                        </label>
                    </div>
                    {listSeoImage && listSeoImage.length > 0
                        ? listSeoImage.map((item, index) => {
                              return (
                                  <div className="seo-item" key={index}>
                                      <div className="seo-color">
                                          <label htmlFor="seo-color">Màu sắc SEO</label>
                                          <div className="color-options">
                                              <TFUSelect
                                                  options={listColor}
                                                  name="colorSeo"
                                                  id="colorSeo"
                                                  ref={addRef}
                                                  onChanged={(value) => {
                                                      let lstSeoImage = [...listSeoImage];
                                                      //kiểm tra xem có màu trong list chưa
                                                      let existed = lstSeoImage.find((x) => x.colorSeo == value);
                                                      if (existed) {
                                                          Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Màu sắc đã tồn tại");
                                                          return;
                                                      }
                                                      let seoItem = lstSeoImage[index];
                                                      seoItem.colorSeo = value;
                                                      setListSeoImage(lstSeoImage);
                                                  }}
                                                  value={item.colorSeo}
                                              />
                                          </div>
                                      </div>
                                      <div className="seo-image">
                                          <label htmlFor="seo-image">Hình ảnh SEO</label>
                                          <TFUImage
                                              name={`imageSeo-${index}}`}
                                              id={`imageSeo-${index}}`}
                                              ref={addRef}
                                              onChanged={(name, value) => {
                                                  let lstSeoImage = [...listSeoImage];
                                                  let seoItem = lstSeoImage[index];
                                                  seoItem.imageSeo = value;
                                                  setListSeoImage(lstSeoImage);
                                              }}
                                          />
                                          <div className="group-img">
                                              <div className="col-img">{item.imageSeo && <img src={item.imageSeo} />}</div>
                                          </div>
                                      </div>
                                      <div
                                          className="seo-remove"
                                          onClick={() => {
                                              let lstSeoImage = [...listSeoImage];
                                              lstSeoImage.splice(index, 1);
                                              setListSeoImage(lstSeoImage);
                                          }}
                                      >
                                          <label htmlFor="seo-image">Hành động</label>
                                          <div className="text-danger">Xóa</div>
                                      </div>
                                  </div>
                              );
                          })
                        : ""}
                </div>
            </Wapper>
        </CommonModal>
    );
};

export default ModalUpdateProduct;
const Wapper = styled.div`
    .hide {
        display: none;
    }

    .box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .text-add {
            color: #64c5b1;
        }
    }

    .box-input {
        display: flex;

        .w50 {
            width: 50%;
        }
    }

    .group-img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 -3px;
        .col-img {
            width: 20%;
            max-width: 20%;
            margin: 0 3px;
            position: relative;

            img {
                width: 100%;
                aspect-ratio: 1/1;
                margin-bottom: 6px;
            }
            .remove {
                position: absolute;
                right: 8px;
                top: 8px;
            }
        }
    }

    .seo-item {
        display: flex;
    }

    .seo-color {
        width: 50%;
    }

    .seo-image {
        flex: 1;
        margin: 0 5px;
    }

    .seo-remove {
        width: 35%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
`;
ModalUpdateProduct.propTypes = {
    isShowModal: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.number,
    onSaveContinue: PropTypes.func,
};
