import BaseServices from "./BaseServices";

const OrderServices = {
    SyncOrder: async (postData) => {
        return await BaseServices.Post("/api/orders/sync-order", postData);
    },
    GetOrdersPage: async (paging) => {
        return await BaseServices.Post("/api/orders/get-orders", paging);
    },
    GetOrderPageCount: async (platform) => {
        return await BaseServices.Get(`/api/orders/get-orders-page-count/${platform}`);
    },
    GetOrderDetail: async (id) => {
        return await BaseServices.Get("/api/orders/orders-detail/" + id);
    },
    PrintShippingLabel: async (orderId, shippingCompanyId, shipModel = {}, shippingServiceType = 0) => {
        return await BaseServices.Post(`/api/orders/print-shipping-label?orderId=${orderId}&shippingCompanyId=${shippingCompanyId}&shippingServiceType=${shippingServiceType}`, shipModel);
    },
    CalculateShippingFee: async (orderId, shippingCompanyId) => {
        return await BaseServices.Post(`/api/orders/calculate-shipping-fee?orderId=${orderId}&shippingCompanyId=${shippingCompanyId}`, null);
    },
    PrintShippingLabelRanus: async (orderId, shippingCompanyId) => {
        return await BaseServices.Post(`/api/orders/print-shipping-label-ranus?orderId=${orderId}&shippingCompanyId=${shippingCompanyId}`);
    },
    SetStatusToReadyToShip: async (orderId) => {
        return await BaseServices.Post(`/api/orders/ready-to-ship/${orderId}`, null);
    },
    GetListOrderAffiliateInfo: async (paging) => {
        return await BaseServices.Post(`/api/orders/get-list-order-affiliate`, paging);
    },
    ChangeOrderStatus: async (orderId, status) => {
        return await BaseServices.Get(`/api/orders/change-order-status?id=${orderId}&status=${status}`);
    },
    ChangeMultiOrderStatus: async (orderIds, status) => {
        return await BaseServices.Post(`/api/orders/change-multi-order-status?status=${status}`, orderIds);
    },
    GetHandleOverToShipper: async (orderId) => {
        return await BaseServices.Get(`/api/orders/get-handleover-to-shipper?orderId=${orderId}`);
    },
    GetRanusShippingLabel: async (orderId) => {
        return await BaseServices.Get(`/api/Orders/get-ranus-shipping-label?orderId=${orderId}`);
    },
    GetNextOrderStatus: async (status) => {
        return await BaseServices.Get(`/api/Orders/get-next-order-status/${status}`);
    },
    MomoRefund: async (orderId) => {
        return await BaseServices.Get(`/api/Orders/momo-refund/${orderId}`);
    },
    UpdateOrderStatusById: async (orderId) => {
        return await BaseServices.Get(`/api/Orders/update-order-status-by-id/${orderId}`);
    },
    VerifyOrderProduct: async (model) => {
        return await BaseServices.Post(`/api/Orders/verify-order-product`, model);
    },
    GetDesignModelByOrderProductId: async (orderProductId) => {
        return await BaseServices.Get(`/api/Orders/get-design-model-by-order-product-id/${orderProductId}`);
    },
    GetDesignExtendByOrderProductId: async (orderProductId) => {
        return await BaseServices.Get(`/api/Orders/get-design-extend-by-order-product-id/${orderProductId}`);
    },
    InsertRangeImageEnhance: async (models, designOrderId) => {
        return await BaseServices.Post(`/api/Orders/insert-range-image-enhance/${designOrderId}`, models);
    },
    RenderArtworkWithEnhanceUrl: async (orderProductId) => {
        return await BaseServices.Get(`/api/Orders/render-artwork-with-enhanceurl/${orderProductId}`);
    },
    UpdateTranscriptStatus: async (model) => {
        return await BaseServices.Post(`/api/Orders/update-transcript-status`, model);
    },
    GetOrderGrabDeliveryInfoById: async (shippingOrderNumber) => {
        return await BaseServices.Get(`/api/Orders/get-order-grab-delivery-info-by-id/${shippingOrderNumber}`);
    },
    GetLogisticBookingModels: async (paging) => {
        return await BaseServices.Post(`/api/Orders/get-logistic-booking-models`, paging);
    },
    CancelDelivery: async (deliveryId, shippingCompanyId) => {
        return await BaseServices.Get(`/api/Orders/cancel-delivery/${deliveryId}/${shippingCompanyId}`);
    },
    GetLogisticCountModels: async () => {
        return await BaseServices.Get(`/api/Orders/get-logistic-count-model`);
    },
    CancelOrderByAdmin: async (model) => {
        return await BaseServices.Post(`/api/Orders/cancel-order-by-admin`, model);
    },
    GetOrderPageCountModel: async (platform) => {
        return await BaseServices.Get(`/api/Orders/get-order-page-count-model/${platform}`);
    },
    GetLogisticBulkBookingModels: async (paging) => {
        return await BaseServices.Post(`/api/Orders/get-logistic-bulk-booking-models`, paging);
    },
    AllocateBulkBooking: async (models) => {
        return await BaseServices.Post(`/api/Orders/allocate-bulk-booking`, models);
    },
    GetOrderGrabDeliveryInfoByOrderId: async (listOrderId) => {
        return await BaseServices.Post(`/api/Orders/get-order-grab-delivery-info-by-order-id`, listOrderId);
    },
    CreateMultiOrder: async (listOrderId, shippingCompanyId, shippingServiceType) => {
        return await BaseServices.Post(`/api/Orders/create-multi-order/${shippingCompanyId}/${shippingServiceType}`, listOrderId);
    },
    GetMultiOrderGrabDeliveryInfo: async (sortingCode) => {
        return await BaseServices.Get(`/api/Orders/get-multi-order-info/${sortingCode}`);
    },
    GetOrderTupleByOrderNumber: async (orderNumber) => {
        return await BaseServices.Get(`/api/Orders/get-order-tuple-by-order-number/${orderNumber}`);
    },
    MarkIsConnect: async (orderId, isConnect) => {
        return await BaseServices.Get(`/api/Orders/mark-is-connect/${orderId}/${isConnect}`);
    },
    RemarkOrderByAdmin: async (model) => {
        return await BaseServices.Post(`/api/Orders/remark-order-by-admin`, model);
    },
    MarkIsBoom: async (orderId) => {
        return await BaseServices.Get(`/api/Orders/mark-is-boom/${orderId}`);
    },
    RemoveIsBoom: async (orderId) => {
        return await BaseServices.Get(`/api/Orders/remove-is-boom/${orderId}`);
    },
};
export default OrderServices;
