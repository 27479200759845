import React, { useState, useEffect, useRef } from "react";
import Loading from "../based/Loading";
import Pubsub from "pubsub-js";
import { CONSTANTS, MAINMENU } from "../based/Constants";
import { NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import { ConfirmDialog } from "../based/Dialog";
import Common from "../based/Common";
import Language from "../based/Language";
import CreatorModal from "./CreatorModal";
import CommonTable from "../based/CommonTable";
import CheckBox from "../based/inputs/CheckBox";
import CreatorServices from "../based/services/CreatorServices";
import Breadcrumbs from "../based/Breadcrumbs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import LevelConfigService from "../based/services/LevelConfigService";

const initCreator = {
    id: 0,
    userName: "",
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    address: "",
    provinceCode: "",
    districtCode: "",
    wardCode: "",
    birthday: new Date(),
};

export default function Creator(props) {
    const [isBusy, setIsBusy] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [datas, setDatas] = useState([]);
    const [paging, setPaging] = useState(Common.Paging);
    const [tableGuid, setTableGuid] = useState(Common.generateGuid);
    const tableRef = useRef(null);
    const [selectedAll, setSelectedAll] = useState(false);
    const [creator, setCreator] = useState(initCreator);
    const [lelveOptions, setLevelOptions] = useState([]);
    useEffect(() => {
        setIsBusy(false);
        getDatas(paging);
        Pubsub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.Creator });
        getLevels();
    }, []);

    async function getLevels() {
        setIsBusy(true);
        let [error, data] = await LevelConfigService.GetAllLevelConfig();
        if (!error && data) {
            setLevelOptions(data);
            setIsBusy(false);
        } else {
            setLevelOptions([]);
            setIsBusy(false);
        }
    }

    async function getDatas(newPaging) {
        setIsBusy(true);
        setSelectedAll(false);
        const [err, data] = await CreatorServices.GetCreators(newPaging);
        if (!err && data) {
            setIsBusy(false);
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(Common.Paging);
            setIsBusy(false);
        }
    }
    async function _handleSetLevel(level) {
        let selected = datas.filter((x) => x.checked);
        if (selected && selected.length > 0) {
            let ids = [];
            selected.map((item) => ids.push(item.id));
            ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn thay đổi cấp độ cho nhà sáng tạo đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsBusy(true);
                    const [err, data] = await CreatorServices.UpdateCreatorsLevel(ids, level);
                    if (!err && data) {
                        resolve({ title: "Thành công", msg: "Thay đổi thành công." });
                        setIsBusy(false);
                        getDatas(paging);
                    } else {
                        setIsBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                    }
                });
            });
        } else Notify(NOTIFY.INFO, "Thông báo", "Không có nhà sáng tạo nào được chọn. Vui lòng kiểm tra lại!");
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="p-x-10 box__actions">
                <div className="form-row">
                    {datas && datas.length > 0 ? (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" /> Sửa
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={
                                    <span>
                                        <i className="fa fa-cog m-r-5" /> Điều chỉnh cấp độ (level)
                                    </span>
                                }
                                variant="custom"
                                size="sm"
                                className={dataChecked && dataChecked.length > 0 ? "" : "disable"}
                            >
                                {lelveOptions &&
                                    lelveOptions.length > 0 &&
                                    lelveOptions.map((lvl, k) => {
                                        return (
                                            <Dropdown.Item key={k} onClick={() => _handleSetLevel(lvl.id)}>
                                                Level {lvl.agencyLevel}{" "}
                                            </Dropdown.Item>
                                        );
                                    })}
                            </DropdownButton>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center">
                        <CheckBox checked={datas != null && datas.length > 0 ? selectedAll : false} name="select_all" id="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th className="cursor-pointer text-center" width={50}>
                        ID
                    </th>
                    <th className="cursor-pointer text-center">Ảnh đại diện</th>
                    <th className="cursor-pointer text-center">Họ tên</th>
                    <th className="cursor-pointer text-center" width="150">
                        Cấp độ (Level)
                    </th>
                    <th className="cursor-pointer text-center">Tên nghệ danh</th>
                    <th className="cursor-pointer text-center">Số điện thoại</th>
                    <th className="cursor-pointer text-center">Email</th>
                    <th className="cursor-pointer text-center">Địa chỉ</th>
                    <th className="cursor-pointer text-center">Email Xác thực</th>
                    <th className="cursor-pointer text-center">Admin Xác thực</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        if (datas && datas.length > 0)
            return (
                <tbody>
                    {datas.map((item, i) => {
                        return (
                            <React.Fragment key={i}>
                                {
                                    <tr key={i} onClick={() => _handleClickRow(i)}>
                                        {
                                            <td width={40} className={"center vertical-align-middle"}>
                                                <CheckBox checked={item.checked ? item.checked : false} name={`checkBox_${i}`} id={item.id.toString()} label=" " onCheckedChange={(name, value) => _handleCheckedChange(item, i, value)} />
                                            </td>
                                        }
                                        <td className="text-center vertical-align-middle">{item.id}</td>
                                        <td className="text-center vertical-align-middle">
                                            <a className="iconImageDefault full-width">
                                                <img src={item.avatar} className="variant-avatar" id={`tfutooltip_${item.id}`} />
                                            </a>
                                        </td>
                                        <td className="vertical-align-middle">{`${item.firstName} ${item.lastName}`}</td>
                                        <td className="vertical-align-middle text-center">Level {item.agencyLevel}</td>
                                        <td className="vertical-align-middle">{item.displayName}</td>
                                        <td className="text-center vertical-align-middle">{item.phoneNumber}</td>
                                        <td className="text-center vertical-align-middle">{item.email}</td>
                                        <td className="vertical-align-middle">{item.fullAddress}</td>
                                        <td className="vertical-align-middle">
                                            {item.emailConfirmed ? (
                                                <span className="status__active">
                                                    <i className="fa fa-check m-r-5"></i>Đã xác thực
                                                </span>
                                            ) : (
                                                <span className="status__unactive">
                                                    <i className="fa fa-exclamation m-r-5"></i>Chưa xác thực
                                                </span>
                                            )}
                                        </td>
                                        <td className="text-center vertical-align-middle">
                                            {item.verified ? (
                                                <span className="status__active">
                                                    <i className="fa fa-check m-r-5"></i>Admin đã xác thực
                                                </span>
                                            ) : (
                                                <span className="status__unactive">
                                                    <i className="fa fa-exclamation m-r-5"></i>Admin chưa xác thực
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
            );
        return (
            <tbody>
                <tr>
                    <td colSpan="12" className="center">
                        {Language.getValue("common.noRecords")}
                    </td>
                </tr>
            </tbody>
        );
    }

    function _handleClickRow(index) {
        let data = [...datas];
        data[index].checked = !data[index].checked;
        setDatas(data);
        setSelectedAll(data.filter((x) => x.checked).length == datas.length);
    }

    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }

    function _handleCheckedChange(item, idx, value) {
        let datasUpdate = [...datas];
        let itemUpdate = datasUpdate[idx];
        itemUpdate.checked = value;
        setDatas(datasUpdate);
        setSelectedAll(datasUpdate.filter((x) => x.checked).length == datas.length);
    }

    function _handleEdit() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsShowModal(true);
            setCreator(dataChecked[0]);
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một nhà sáng tạo để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleDelete() {
        let creatorsDelete = datas.filter((x) => x.checked);
        if (creatorsDelete && creatorsDelete.length > 0) {
            let ids = [];
            creatorsDelete.map((item) => ids.push(item.id));
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsBusy(true);
                    const [err, data] = await CreatorServices.DeleteCreators(ids);
                    if (!err && data) {
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        setIsBusy(false);
                        getDatas(paging);
                    } else {
                        setIsBusy(false);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.");
                    }
                });
            });
        } else Notify(NOTIFY.INFO, "Thông báo", "Không có nhà sáng tạo nào được chọn. Vui lòng kiểm tra lại!");
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Nhà sáng tạo" parentPage="Người dùng" />
            <Loading show={isBusy} msg={"Đang tải dữ liệu..."} />
            <CommonTable data={datas} paging={paging} ref={tableRef} tableGuid={tableGuid} headerCheckbox searchBasic renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getDatas} />
            <CreatorModal
                isShowModal={isShowModal}
                creator={creator}
                onSaveSucceed={() => {
                    getDatas(paging);
                    setIsShowModal(false);
                }}
                onClose={() => setIsShowModal(false)}
            />
        </React.Fragment>
    );
}
