import React, { useState } from "react";
import styled from "styled-components";
import { NOTIFY } from "../based/Constants";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import BankingServices from "../based/services/BankingServices";

function SMSManualModal({ show, onClose }) {
    const [busy, setBusy] = useState(false);
    const [remark, setRemark] = useState("");

    const PushSMS = async () => {
        if (remark === "" && remark.length != 6) {
            Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng nhập ghi chú");
            return;
        }
        const [err, data] = await BankingServices.AdminHookSMS({
            code: remark,
        });
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Gửi mã thành công");
            onClose();
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Gửi mã thất bại");
        }
    };

    return (
        <CommonModal
            show={show}
            isBusy={busy}
            busyMsg={"Đang tải"}
            title={`Xử lý SMS Manual`}
            onClose={() => onClose()}
            size="md"
            onSave={() => {
                PushSMS();
            }}
        >
            <Wrapper>
                <div className="form-group">
                    <label htmlFor="" className="form-label required">
                        Code
                    </label>
                    <input
                        type="text"
                        value={remark}
                        className="form-control"
                        onChange={(e) => {
                            setRemark(e.target.value);
                        }}
                    />
                </div>
            </Wrapper>
        </CommonModal>
    );
}

export default SMSManualModal;

const Wrapper = styled.div``;
