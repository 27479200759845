import React, { useEffect, useState } from "react";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { CONSTANTS, NOTIFY, PLATFORM } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import ProductServices from "../based/services/ProductServices";

const initPaging = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: "id",
    orderDirection: "asc",
    platform: PLATFORM.LOTUS,
    productPushingStatus: -1,
};

const columns = [
    { name: "checkbox", displayName: "", type: "checkBox", width: 40 },
    { name: "productAvatar", displayName: "Ảnh", type: "image", width: 100 },
    { name: "name", displayName: "Tên sản phẩm" },
    { name: "modifiedDate", displayName: "Cập nhập gần nhất" },
    { name: "action", displayName: "Tình trạng", type: "button", width: 420 },
];

const arrPlatform = [PLATFORM.LOTUS];

export default function ProductsFIFOListing(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [paging, setPaging] = useState(initPaging);
    const [datas, setDatas] = useState();
    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        let currentPaging = { ...paging, platform: PLATFORM.LOTUS };
        getDatas(currentPaging);
    }, []);

    async function getDatas(newPaging) {
        setIsLoading(true);
        setSelectedAll(false);
        let [error, data] = await ProductServices.GetProducts(newPaging);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
        } else {
            setDatas([]);
            setPaging(newPaging);
            setIsLoading(false);
        }
    }

    function _renderSourceTab() {
        return (
            <div className='row col-md-12'>
                <ul className='nav nav-tabs tabs-bordered'>
                    {arrPlatform.map((item, i) => {
                        var platformName = Common.DetectPlatformName(item);
                        platformName = platformName == "facebook" ? "Seller" : platformName;
                        return (
                            <li key={i} className='nav-item'>
                                <a
                                    href='#'
                                    className={item == paging.platform ? "nav-link active " : "nav-link"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getDatas({ ...paging, platform: item });
                                    }}
                                >
                                    <span className='d-none d-sm-block'>{platformName.toLocaleUpperCase()}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
    function _renderAction() {
        let grButton = [];
        switch (paging.platform) {
            case PLATFORM.LOTUS:
                grButton.push(_renderActionBtn.addLOTUSProduct());
                grButton.push(_renderActionBtn.editLOTUSProduct());
                grButton.push(_renderActionBtn.deleteLOTUSProduct());
                break;
            default:
                break;
        }
        return (
            <React.Fragment>
                <div className='row flex-row p-x-10'>
                    <div className='form-group row col-md-12'>{grButton}</div>
                </div>
            </React.Fragment>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr className='text-center'>
                    {columns.map((item, index) => {
                        let width = item.width || null;
                        let checkBox =
                            item.type == "checkBox" ? (
                                <CheckBox
                                    id={Common.generateGuid()}
                                    checked={selectedAll}
                                    name='select_all'
                                    label=' '
                                    onCheckedChange={(name, value) => {
                                        _handleCheckedAll(value);
                                    }}
                                />
                            ) : null;
                        return (
                            <th width={width} className='cursor-pointer center'>
                                {!checkBox ? <span className='ml-3'>{item.displayName} </span> : checkBox}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((ele, idx) => {
                        return (
                            <tr key={idx} className='text-center'>
                                <td className='text-left vertical-align-middle'>
                                    <CheckBox
                                        id={Common.generateGuid()}
                                        checked={ele.checked}
                                        name='select_all'
                                        label=' '
                                        onCheckedChange={(name, value) => {
                                            let data = [...datas];
                                            data.map((item) => {
                                                item.checked = false;
                                                return item;
                                            });
                                            let currentDt = data[idx];
                                            currentDt.checked = value;
                                            setDatas(data);
                                        }}
                                    />
                                </td>
                                <td className='text-left vertical-align-middle'>
                                    <a className='iconImageDefault full-width'>
                                        <img src={!ele.productAvatar ? "https://beta-cdn.fifohub.com/images_system/no-image.png" : ele.productAvatar}></img>
                                    </a>
                                </td>
                                <td className='text-left vertical-align-middle'>{ele.name}</td>
                                <td className='text-left vertical-align-middle'>{Common.formatDate(new Date(ele.modifiedDate), "datetime")}</td>
                                <td className='text-left vertical-align-middle'>{ele.isActive ? "Hoạt động" : "Không hoạt động"}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={columns.length + 1} className='text-center'>
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }

    const _renderActionBtn = {
        addLOTUSProduct: () => {
            return (
                <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleAdd()}>
                    <i className='fa fa-plus m-r-5' />
                    Thêm mới
                </button>
            );
        },
        editLOTUSProduct: () => {
            return (
                <button className='btn btn-custom btn-sm m-r-5' onClick={() => _handleEdit()}>
                    <i className='fa fa-edit m-r-5' />
                    Chỉnh sửa
                </button>
            );
        },
        deleteLOTUSProduct: () => {
            return (
                <button className='btn btn-danger btn-sm m-r-5' onClick={() => _handleDelete()}>
                    <i className='fa fa-trash-o m-r-5' />
                    Xóa
                </button>
            );
        },
    };

    function _handleCheckedAll(value) {
        if (!datas || datas.length == 0) return;
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }

    function _handleAdd() {
        let url = "/product-editor/new";
        switch (paging.platform) {
            case PLATFORM.LOTUS:
                url = "/product-editor/lotus/new";
                break;
            default:
                break;
        }
        if (props && props.history) props.history.push(url);
        else window.location.href = url;
    }

    function _handleEdit() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            let sp = dataChecked[0];
            if (sp != null) {
                if (sp.platforms && sp.platforms.length > 1) {
                    ConfirmDialog(
                        "Xác nhận",
                        "Sản phẩm đang được bán trên " + sp.platforms.length + " sàn. Bạn chắc chắn muốn sửa sản phẩm đã chọn? ",
                        () => {
                            return new Promise(async (resolve, reject) => {
                                reject({ title: "" });
                                let url = "/product-editor/" + sp.id;
                                if (sp.productId == 0) {
                                    if (props.tabIndex) url = `/product-editor/${Common.DetectPlatformName(paging.platform)}/${sp.id}`;
                                } else {
                                    url = `/product-variants-editor/${Common.DetectPlatformName(props.tabIndex)}/${sp.productId}/edit/${sp.id}`;
                                }
                                if (props && props.history) props.history.push(url);
                                else window.location.href = url;
                            });
                        },
                        true
                    );
                } else {
                    let url = "/product-editor/" + sp.id;
                    if (sp.productId == 0 || !sp.productId) {
                        url = `/product-editor/${Common.DetectPlatformName(paging.platform)}/${sp.id}`;
                    } else {
                        url = `/product-variants-editor/${Common.DetectPlatformName(props.tabIndex)}/${sp.productId}/edit/${sp.id}`;
                    }
                    if (props && props.history) props.history.push(url);
                    else window.location.href = url;
                }
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một sản phẩm để thực hiện. Vui lòng kiểm tra lại!");
    }

    function _handleDelete() {
        let dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            let ids = [];
            dataChecked.map((d) => ids.push(d.id));
            if (ids.length > 0) {
                ConfirmDialog(
                    "Xác nhận",
                    "Sản phẩm đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa sản phẩm đã chọn? ",
                    () => {
                        return new Promise(async (resolve, reject) => {
                            setIsLoading(true);
                            const [err, data] = await ProductServices.DeleteProducts(ids, props.tabIndex);
                            if (!err) {
                                resolve({ title: "Thành công", msg: "Xóa thành công." });
                                getDatas({ ...paging });
                            } else {
                                if (err.data) reject({ title: "Có lỗi", msg: err.data });
                                else reject({ title: "Có lỗi", msg: CONSTANTS.MSG_ERROR });
                            }
                            setIsLoading(false);
                        });
                    },
                    true
                );
            }
        } else Notify(NOTIFY.INFO, "Thông báo", "Yêu cầu chọn một sản phẩm để thực hiện. Vui lòng kiểm tra lại!");
    }

    return (
        <React.Fragment>
            <Loading show={isLoading} msg='Đang tải dữ liệu...' />
            {_renderSourceTab()}
            <CommonTable renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} paging={paging} data={datas} searchBasic onFilter={(paging) => getDatas(paging)} />
        </React.Fragment>
    );
}
