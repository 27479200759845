import BaseServices from "./BaseServices";

const PlatformServices = {
    PlatformStoresUser: async (platforms, status, productId = 0) => {
        return await BaseServices.Post(`/api/connectPlatform/get-stores-platform/${productId}?status=${status}`, platforms);
    },
    DeleteConnectStore: async (id) => {
        return await BaseServices.Post(`/api/connectPlatform/delete-connect-store/${id}`);
    },
    GetWebhooksUrl: async (platform) => {
        return await BaseServices.Get(`/api/connectPlatform/get-webhooks-url?platform=${platform}`);
    },
    //lấy danh sách ngành hàng theo platform
    GetCategories: async (paging) => {
        return await BaseServices.Post("/api/Category/get-list-parent-category", paging);
    },
    //sync sản phẩm trên platform về FIFO
    SyncProductsToFiFo: async (model) => {
        return await BaseServices.Post("/api/agencyproducts/sync-products", model);
    },
    //sync sản phẩm trên platform về FIFO
    CheckAdminConnectPlatform: async (platform) => {
        return await BaseServices.Post("/api/connectPlatform/check-connect-platform?platform=" + platform, null);
    },
    ConnectToPlatform: async (param) => {
        return await BaseServices.Get("/api/connectPlatform/connect-to-platform" + (param ? param : ""));
    },
    ConnectToSendo: async (obj) => {
        return await BaseServices.Post("/api/connectPlatform/connect-to-sendo", obj);
    },
    RefreshAccessToken: async (model) => {
        return await BaseServices.Post(`/api/connectPlatform/refresh-access-token`, model);
    },
    //sync thuộc tính
    SyncAttributes: async (model) => {
        return await BaseServices.Post("/api/connectPlatform/sync-attributes/", model);
    },
    TopPickShopee: async () => {
        return await BaseServices.Get("/api/connectPlatform/top-pick-shopee");
    },
};
export default PlatformServices;
