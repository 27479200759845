import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import DragAndDropIndex from "../based/DragAndDropIndex";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import FolderProductPrototypeServices from "../based/services/FolderProductPrototypeServices";
import ModalAddOrUpdateFolder from "./ModalAddOrUpdateFolder";
import ModalAddProduct from "./ModalAddProduct";
import ModalUpdateProduct from "./ModalUpdateProduct";

const initPage = {
    pageSize: 10,
    pageNumber: 1,
};

let modelUpdateIndex = {
    id: 0,
    parentFolderId: 0,
    folderType: 9,
    index: 0,
};

function FolderProductPrototype() {
    const [data, setData] = useState([]);
    const [paging, setPaging] = useState(initPage);
    const [isShowModalFolder, setIsShowModalFolder] = useState(false);
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    const [isShowModalUpdateProduct, setIsShowModalUpdateProduct] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const tableRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [product, setProduct] = useState([]);
    const [pagingProduct, setPagingProduct] = useState(initPage);
    const tableProductRef = useRef(null);
    const [folderId, setFolderId] = useState();
    const [itemUpdate, setItemUpdate] = useState({});

    useEffect(() => {
        setBusy(false);
        getData(paging);
    }, []);

    async function getData(newPaging) {
        setBusy(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await FolderProductPrototypeServices.GetFolderAppProductByPage(newPaging);
        if (!err && data) {
            setData(data.listObjects);
            setPaging(data.paging);
            setBusy(false);
        } else {
            setData([]);
            setBusy(false);
        }
    }

    async function updateIndexFolder(id, index) {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.UpdateIndexStockFolder({
            ...modelUpdateIndex,
            id,
            index,
        });
        if (!err && data) {
            getData(paging);
            setFolderId(0);
            setBusy(false);
        } else {
            setBusy(false);
        }
    }

    function _renderHeader() {
        return (
            <thead>
                <tr className="text-center">
                    <th width={40} className="center"></th>
                    <th className="cursor-pointer">Folder</th>
                </tr>
            </thead>
        );
    }

    const _handleDelete = async (id) => {
        var ids = data.filter((x) => x.selected).map((x) => x.id);
        if (ids && ids.length > 0)
            ConfirmDialog("Xác nhận xóa?", "Xin vui lòng xác nhận để xóa ", () => {
                return new Promise(async (resolve, reject) => {
                    setBusy(true);
                    setMsg("Đang xóa dữ liệu...");
                    let [error, data] = await FolderProductPrototypeServices.DeleteFolderAppProduct(ids[0]);
                    if (!error && data) {
                        setBusy(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getData(paging);
                    } else {
                        setBusy(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        else Notify(NOTIFY.INFO, "Thông báo", "Chưa chọn folder!");
    };

    function renderRow(item, idx) {
        return (
            <React.Fragment>
                <td>
                    <CheckBox
                        id={idx.toString()}
                        name={idx.toString()}
                        checked={item.selected ? item.selected : false}
                        label=" "
                        onCheckedChange={(name, value) => {
                            let dt = [...data];
                            dt.map((x) => (x.selected = false));
                            dt[idx].selected = value;
                            setData(dt);
                            if (value) {
                                setFolderId(item.id);
                            } else {
                                setFolderId(0);
                            }
                        }}
                    />
                </td>
                <td style={{ width: 200, textAlign: "center" }}>
                    <strong>{item.name}</strong>
                </td>
            </React.Fragment>
        );
    }

    function _renderBody() {
        return <DragAndDropIndex data={data} renderRow={renderRow} updateIndexFolder={updateIndexFolder} />;
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom mb-3"
                    onClick={() => {
                        setFolderId(0);
                        setIsShowModalFolder(true);
                    }}
                >
                    Thêm mới
                </button>
                <button
                    onClick={() => {
                        setIsShowModalFolder(true);
                    }}
                    className={dataChecked && dataChecked.length > 0 ? "btn btn-custom mb-3 ml-3" : "btn btn-custom ml-3 mb-3 disable"}
                >
                    Sửa
                </button>
                <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger mb-3 ml-3" : "btn btn-danger ml-3 mb-3 disable"} onClick={() => (dataChecked && dataChecked.length > 0 ? _handleDelete() : {})}>
                    Xóa
                </button>
            </React.Fragment>
        );
    }

    //Product

    useEffect(() => {
        getProduct(initPage);
    }, [folderId]);

    async function updateIndexProductInFolder(idProduct, newIndex) {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.UpdateIndexProductInFolder({
            idProduct,
            newIndex,
            folderId: folderId,
        });
        if (!err && data) {
            getProduct(initPage);
            setBusy(false);
        } else {
            setBusy(false);
        }
    }

    const getProduct = async (paging) => {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.GetProductByFolderId(paging, folderId);
        if (!err && data) {
            setProduct(data.listObjects);
            setPagingProduct(data.paging);
            setBusy(false);
        } else {
            setProduct([]);
            setBusy(false);
        }
    };

    function _renderHeaderProduct() {
        return (
            <thead>
                <tr className="text-center">
                    <th>Hình</th>
                    <th>Tên sản phẩm</th>
                    <th>Tên hiển thị</th>
                    <th>Giá</th>
                    <th>Hình thực tế</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }
    const handleRemoveProduct = async (id) => {
        setBusy(true);
        let [err, data] = await FolderProductPrototypeServices.DeleteFolderConAppProduct(folderId, id);
        if (!err && data) {
            getProduct(initPage);
            setBusy(false);
        } else {
            setBusy(false);
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Xóa lỗi");
        }
    };

    function renderRowProduct(item, idx) {
        return (
            <React.Fragment>
                <td style={{ width: 100, textAlign: "center" }}>{item.productAvatar ? <img src={item.productAvatar} /> : <img />}</td>
                <td style={{}}>{item.name}</td>
                <td style={{ textAlign: "center" }}>{item.nameShow}</td>
                <td style={{ textAlign: "center" }}>{item.price}</td>
                <td style={{ textAlign: "center" }}>{item.quantityImage} hình</td>
                <td style={{ width: 200, textAlign: "center" }}>
                    <button
                        className="btn btn-custom"
                        onClick={async () => {
                            await setItemUpdate(item);
                            // setIsShowModalUpdateProduct(true);
                            window.open("/prototype-add/" + item.id, "_blank");
                        }}
                    >
                        Sửa
                    </button>
                    <button
                        className="btn ml-3 btn-danger"
                        onClick={() => {
                            handleRemoveProduct(item.id);
                        }}
                    >
                        Xóa
                    </button>
                </td>
            </React.Fragment>
        );
    }

    function _renderBodyProduct() {
        return <DragAndDropIndex data={product} renderRow={renderRowProduct} updateIndexFolder={updateIndexProductInFolder} />;
    }
    function _renderActionProduct() {
        let dataChecked = null;
        let isEnabled = false;
        if (data && data.length > 0) {
            dataChecked = data.filter((x) => x.selected);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <React.Fragment>
                <button
                    className={`btn btn-custom mb-3 ${dataChecked && dataChecked.length > 0 ? "" : "disabled"}`}
                    onClick={() => {
                        setIsShowModalAddProduct(true);
                    }}
                >
                    Thêm sản phẩm +
                </button>
            </React.Fragment>
        );
    }

    return (
        <Wapper>
            <Breadcrumbs currentPage="Quản lý folder sản phẩm phôi tự thiết kế" />
            <Loading show={isBusy} msg={msg} />
            <div className="body-page">
                <div className="folder-list">
                    <CommonTable data={data} paging={paging} ref={tableRef} hideSearchBasicButton renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={(paging) => getData(paging)} />
                </div>
                <div className="product-list">
                    <CommonTable
                        data={product}
                        paging={pagingProduct}
                        onFilter={(pagingProduct) => getProduct(pagingProduct)}
                        ref={tableProductRef}
                        hideSearchBasicButton
                        renderAction={_renderActionProduct}
                        renderHeader={_renderHeaderProduct}
                        renderBody={_renderBodyProduct}
                    />
                </div>
            </div>
            {isShowModalFolder && (
                <ModalAddOrUpdateFolder
                    isShowModal={isShowModalFolder}
                    onClose={() => {
                        setIsShowModalFolder(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalFolder(false);
                        getData(initPage);
                    }}
                    id={folderId}
                />
            )}
            {isShowModalAddProduct && (
                <ModalAddProduct
                    isShowModal={isShowModalAddProduct}
                    onClose={() => {
                        setIsShowModalAddProduct(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalAddProduct(false);
                        getProduct(initPage);
                    }}
                    folderId={folderId}
                />
            )}
            {/* {isShowModalUpdateProduct && (
                <ModalUpdateProduct
                    isShowModal={isShowModalUpdateProduct}
                    onClose={() => {
                        setIsShowModalUpdateProduct(false);
                    }}
                    onSaveContinue={() => {
                        setIsShowModalUpdateProduct(false);
                        getProduct(initPage);
                    }}
                    item={itemUpdate}
                />
            )} */}
        </Wapper>
    );
}
const Wapper = styled.div`
    .cursor-move {
        cursor: move;
    }
    .body-page {
        display: flex;
        .folder-list {
            width: 30%;
        }
        .product-list {
            width: 70%;
            padding-left: 10px;

            table {
                tr {
                    td {
                        img {
                            width: 100%;
                            aspect-ratio: 1/1;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
`;
export default FolderProductPrototype;
