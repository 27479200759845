import React, { useState, useEffect, useRef } from 'react';
import { Layout } from '../layout/Layout';
import Loading from '../based/Loading';
import PubSub from 'pubsub-js'
import { CONSTANTS, MAINMENU } from '../based/Constants';
import Request from '../based/Request';
import { NOTIFY } from '../based/Constants';
import { Notify } from '../based/Notify';
import { ConfirmDialog } from '../based/Dialog';
import Common from '../based/Common';
import Breadcrumbs from '../based/Breadcrumbs'
import AddOrEdit from './AddOrEdit'
import TFUTable from '../based/TFUTable';

const Menu = {
    id: 0,
    parentId: 0,
    name: '',
    functionCode: '',
    navigateLink: '',
    actions: []
}
const columns = [
    {
        name: 'name',
        displayName: 'Tên menu',
    },
    {
        name: 'functionCode',
        displayName: 'Code',
    },
    {
        name: 'navigateLink',
        displayName: 'Link điều hướng',
    }
]


export default function ManageMenu(props) {
    const [isBusy, setIsBusy] = useState(true);
    const [msg, setMsg] = useState('Đang tải dữ liệu...')
    const [isShowModal, setIsShowModal] = useState(false);
    const [menu, setMenu] = useState(Menu);
    const [actions, setActions] = useState([])
    const [dataDropDown, setDataDropDown] = useState([])

    const [tableGuid, setTableGuid] = useState(Common.generateGuid());
    const tableRef = useRef(null);

    useEffect(() => {
        setIsBusy(false)
        getActions()
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ManageMenus });
    }, [])
    function handleAdd() {
        setMenu(Menu)
        getDataDropdown(0);
    }
    function getActions() {
        Request.Get('/api/menu/get-dropdown-actions')
            .then(res => {
                if (res.success) {
                    let data = res.data
                    setActions(data)
                } else {
                    setActions([])
                }
            })
            .catch(err => {
                setActions([])
            });
    }
    function handleEdit(item) {
        setIsBusy(true)
        setMsg('Đang tải dữ liệu..')
        Request.Get('/api/menu/get-by-id/' + item.id)
            .then(res => {
                if (res.success) {
                    let data = res.data
                    setIsBusy(false)
                    setMsg(null)
                    setMenu(data)
                    getDataDropdown(res.data.id)
                } else {
                    setMenu(Menu)
                    setIsBusy(false)
                    setMsg(null)
                }
            })
            .catch(err => {
                setIsBusy(false)
                setMsg(null)
                setMenu(Menu)
            });
    }
    function handleDelete(items) {
        let ids = []
        items.forEach(item => ids.push(item.id))
        ConfirmDialog('Xác nhận xóa?', 'Bạn chắc chắn muốn xóa dữ liệu đã chọn?', () => {
            return new Promise((resolve, reject) => {
                setIsBusy(true)
                setMsg('Đang xóa dữ liệu...')
                Request.Post('/api/menu/delete/', ids)
                    .then(res => {
                        if (res.success && res.data) {
                            resolve({ title: 'Thành công', msg: 'Xóa thành công.' })
                            setIsBusy(false)
                            setMsg(null)
                            setTableGuid(Common.generateGuid())
                        }
                        else {
                            setIsBusy(false)
                            setMsg(null)
                            Notify(NOTIFY.WARNING, 'Có lỗi', CONSTANTS.MSG_ERROR);
                        }
                    })
                    .catch(err => {
                        setIsBusy(false)
                        setMsg(null)
                    });
            })
        });
    }
    function _handleSaveSucceed(isContinue) {
        setTableGuid(Common.generateGuid())
        if (isContinue) setMenu(Menu)
        else setIsShowModal(false)
    }
    function getDataDropdown(id) {
        setIsBusy(true)
        setMsg('Đang tải dữ liệu..')
        Request.Get('/api/menu/get-dropdown/' + id)
            .then(res => {
                if (res.success) {
                    setIsBusy(false)
                    setMsg(null)
                    setDataDropDown(res.data)
                    setIsShowModal(true)
                } else {
                    setDataDropDown([])
                    setIsBusy(false)
                    setMsg(null)
                }
            })
            .catch(err => {
                setIsBusy(false)
                setMsg(null)
                setDataDropDown([])
            });
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage=' Quản lý menu' parentPage='Cài đặt' hrefParent='/settings' />
            <AddOrEdit
                isShowModal={isShowModal}
                menu={menu}
                actions={actions}
                dataDropDown={dataDropDown}
                onClose={() => setIsShowModal(false)}
                onSaveSucceed={_handleSaveSucceed}
            />
            <Loading show={isBusy} msg={msg} />
            <TFUTable
                fetchDataUrl='/api/menu/get-page'
                ref={tableRef}
                tableGuid={tableGuid}
                headerCheckbox
                search
                onAddItem={handleAdd}
                onEditItem={handleEdit}
                onDeleteItem={handleDelete}
                columns={columns}
            />
        </React.Fragment>
    )
}