import BaseServices from "./BaseServices";

const FolderProductPrototypeServices = {
    CreateFolderConnectionAppProduct: async (objIds, folderId) => {
        return await BaseServices.Post(`/api/ProductPrototype/create-folder-con-app-product/${folderId}`, objIds);
    },
    CreateUpdateFolderAppProduct: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/create-update-folder-app-product`, model);
    },
    DeleteFolderConAppProduct: async (folderId, id) => {
        return await BaseServices.Get(`/api/ProductPrototype/delete-product-in-folder/${folderId}/${id}`);
    },
    DeleteFolderAppProduct: async (id) => {
        return await BaseServices.Get(`/api/ProductPrototype/delete-folder-product-prototype/${id}`);
    },
    GetFolderAppProduct: async (id) => {
        return await BaseServices.Get(`/api/ProductPrototype/get-folder-product-prototype-byId/${id}`);
    },
    GetAppProductByKeyword: async (keyword) => {
        return await BaseServices.Get(`/api/ProductPrototype/get-app-product/${keyword}`);
    },
    GetFolderAppProductByPage: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/get-folder-product-prototype-by-page`, model);
    },
    UpdateFolderConnectionAppProduct: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/update-product-in-folder`, model);
    },
    GetProductByFolderId: async (model, folderId) => {
        return await BaseServices.Post(`/api/ProductPrototype/get-product-prototype-by-folderId/${folderId}`, model);
    },
    GetImageShowByProductId: async (id) => {
        return await BaseServices.Get(`/api/ProductPrototype/get-image-show-by-productId/${id}`);
    },
    UpdateIndexStockFolder: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/update-index-stock-folder`, model);
    },
    UpdateIndexProductInFolder: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/update-index-product-prototype-in-folder`, model);
    },
    GetAttributeValueColorByAppProductId: async (id) => {
        return await BaseServices.Get(`/api/ProductPrototype/get-attribute-value-color-by-id/${id}`);
    },
    GetPrototypeCustomizeModelById: async (id) => {
        return await BaseServices.Get(`/api/ProductPrototype/get-prototype-customize-model-by-id/${id}`);
    },
    CreateUpdatePrototype: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/create-update-prototype`, model);
    },
    HandleCheckImage: async (model) => {
        return await BaseServices.Post(`/api/ProductPrototype/handle-check-image`, model);
    },
};

export default FolderProductPrototypeServices;
