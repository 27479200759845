import PubSub from "pubsub-js";
import React, { useEffect, useMemo, useState } from "react";
import Sticky from "react-stickynode";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { CONSTANTS, MAINMENU, NOTIFY, PRODUCT_EDITOR_TAB } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import RouterConstants from "../based/RouteConstants";
import AttributeServices from "../based/services/AttributeServices";
import ProductServices from "../based/services/ProductServices";
import { ProductContext } from "../context/ProductContext";
import useFormValidate from "../hooks/useFormValidate";
import { MainProductsModel } from "../models/BaseProductModel";
import BasicInfo from "./ProductEditor/BasicInfo";
import NavigateButtons from "./ProductEditor/NavigateButtons";
import SectionDescription from "./ProductEditor/SectionDescription";
import SectionPrices from "./ProductEditor/SectionPrices";
import SectionShipping from "./ProductEditor/SectionShipping";
import SectionStepConfig from "./ProductEditor/SectionStepConfig";
import SectionVariants from "./ProductEditor/SectionVariants";
import TabHeader from "./ProductEditor/TabHeader";

export default function ProductEditor(props) {
    const { addRef, displayError, isValid } = useFormValidate();
    const [mainProduct, setMainProduct] = useState(new MainProductsModel());
    const [variants, setVariants] = useState([]);
    const [attributes, setAttributes] = useState(new Map());
    const [msg, setMsg] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [detailTab, setDetailTab] = useState(PRODUCT_EDITOR_TAB.PRODUCT_INFO);
    const [attributeOptions, setAttributeOptions] = useState([]);
    const [isEditNoneVariant, setEditNoneVariant] = useState(false);

    useEffect(() => {
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ManageProduct });
        let id = props.match.params.productId === "new" ? 0 : parseFloat(props.match.params.productId);
        if (id) {
            let platform = Common.DetectPlatform(props.match.params.platform);
            getProduct(id, platform);
        }
        _getAttributes();
    }, []);

    useEffect(() => {
        _getAttributes(mainProduct.categoryId);
    }, [mainProduct.categoryId]);

    const tabHeader = useMemo(() => <TabHeader tabIndex={detailTab} onTabChanged={setDetailTab} />, [detailTab]);

    async function _getAttributes(categoryId = 0) {
        let [error, data] = await AttributeServices.GetDropdownAttributes(categoryId);
        if (!error && data) setAttributeOptions(data);
    }

    async function getProduct(id, platform) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu..");
        const [err, data] = await ProductServices.GetProductDetails(id, platform);
        if (!err) {
            if (data.variants && data.variants.length > 0) data.multiVariant = true;
            else setEditNoneVariant(true);
            if (data.productDiscounts) {
                if (data.productDiscounts.fromDate) data.productDiscounts.fromDate = new Date(data.productDiscounts.fromDate);
                if (data.productDiscounts.toDate) data.productDiscounts.toDate = new Date(data.productDiscounts.toDate);
            }
            data.isRunServices = false;
            setMainProduct(data);
        }
        setIsLoading(false);
        setMsg(null);
    }

    function ensureProductData(mainProduct) {
        if (mainProduct.id <= 0 || isEditNoneVariant) {
            mainProduct.variants = variants;
            mainProduct.attributes = _getProductAttributes();
        }
        if (!mainProduct.brandId) mainProduct.brandId = 0;
        if (!mainProduct.categoryId) mainProduct.categoryId = 0;
        if (mainProduct.rewardPoints) {
            if (!mainProduct.rewardPoints.rewardPoint) mainProduct.rewardPoints.rewardPoint = 0;
            if (!mainProduct.rewardPoints.commission) mainProduct.rewardPoints.commission = 0;
        }
        mainProduct.platform = Common.DetectPlatform(props.match.params.platform);
    }

    function _handleSave() {
        if (isValid()) {
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            ensureProductData(mainProduct);
            Request.Post(`/api/Product/save`, mainProduct)
                .then((res) => {
                    if (res.success && res.data) {
                        Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                        setIsLoading(false);
                        setMsg(null);
                        ConfirmDialog(
                            "Xác nhận",
                            "Bạn có muốn preview và thay đổi các thông tin chi tiết của biến thể?",
                            () => {
                                return new Promise((resolve, reject) => {
                                    let platform = Common.DetectPlatformName(props.match.params.platform);
                                    let action = "edit";
                                    let productId = res.data.id;
                                    props.history.push(`/product-variants-editor/${platform}/${productId}/${action}`);
                                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                                });
                            },
                            true,
                            () => {
                                props.history.push(RouterConstants.MANAGE_PRODUCT);
                            }
                        );
                    } else {
                        Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                        setIsLoading(false);
                        setMsg(null);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    setMsg(null);
                    if (err.errors) displayError(err.errors);
                });
        }
    }

    function _getProductAttributes() {
        let attrs = [];
        attributes.forEach((value, key) => {
            let obj = {};
            let attribute = attributeOptions.find((m) => m.value == key);
            if (attribute) obj.name = attribute.text;
            obj.id = key;
            obj.values = value;
            attrs.push(obj);
        });
        return attrs;
    }

    function goBack() {
        props.history.push(RouterConstants.MANAGE_PRODUCT);
    }

    function _handleSetIsEditNoneVariant() {
        if (!mainProduct.variants || mainProduct.variants.length == 0) setEditNoneVariant(true);
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Sản phẩm" parentPage="Quản lý sản phẩm" hrefParent="/manage-product" />
            <Loading show={isLoading} msg={msg} />
            <ProductContext.Provider
                value={{
                    mainProduct,
                    setMainProduct,
                    addRef,
                    variants,
                    setVariants,
                    attributes,
                    setAttributes,
                }}
            >
                <div className="container-fluid">
                    <div className="row">
                        {tabHeader}
                        <div className="col-12 col-lg-8 offset-md-2">
                            <Sticky enabled={true} top={70} bottomBoundary={4000} innerZ={2}>
                                <NavigateButtons onSave={_handleSave} onCancel={goBack} />
                            </Sticky>
                            <div className="form-group">
                                {detailTab == PRODUCT_EDITOR_TAB.PRODUCT_INFO ? (
                                    <React.Fragment>
                                        <BasicInfo />
                                        <SectionDescription />
                                        <SectionPrices />
                                        <SectionVariants
                                            attributeOptions={attributeOptions}
                                            history={props.history}
                                            platform={Common.DetectPlatform(props.match.params.platform)}
                                            isEditNoneVariant={isEditNoneVariant}
                                            onSetIsEditNoneVariant={() => _handleSetIsEditNoneVariant()}
                                        />
                                        <SectionShipping />
                                        <SectionStepConfig />
                                    </React.Fragment>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </ProductContext.Provider>
        </React.Fragment>
    );
}
