import React, { useEffect, useMemo, useState } from "react";
import Common from "../based/Common";
import { NOTIFY } from "../based/Constants";
import TextNumber from "../based/inputs/TextNumber";
import TFUSelect from "../based/inputs/TFUSelect";
import CommonModal from "../based/Modal";
import { Notify } from "../based/Notify";
import ProductServices from "../based/services/ProductServices";
import VoucherServices from "../based/services/VoucherServices";
import TFUTooltip from "../based/TFUTooltip";
import useFormValidate from "../hooks/useFormValidate";
import VoucherBrandModel from "../models/Promotion/VoucherBrandModel";

const TAB_SCREENS = {
    INFO: 0,
    PRODUCTS: 1,
};

export default function AddOrEditBrandProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [voucher, setVoucher] = useState(new VoucherBrandModel());
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { addRef, displayError, isValid } = useFormValidate();
    const [voucherOptions, setVoucherOptions] = useState([]);
    const [listProductOption, setListProductOption] = useState([]);
    const { voucherId, productId } = props;

    useEffect(() => {
        if (voucherId > 0 && productId > 0) _getVoucherById(voucherId, productId);
        else setVoucher(new VoucherBrandModel());
        _getVoucherOptions({ ...Common.Paging, pageSize: 50 });
        _getListProduct({ ...Common.Paging, pageSize: 50 });
    }, [props.isShowModal]);

    /** handle save */
    async function _handleSave(isContinue) {
        if (isValid()) {
            let obj = { ...voucher };
            setIsLoading(true);
            setMsg("Đang lưu dữ liệu..");
            console.log("obj", obj);
            let [err, data] = await VoucherServices.CreateUpdateVoucherBrandProduct(obj);
            if (!err) {
                Notify(NOTIFY.SUCCESS, "Thành công", "Lưu thành công");
                props.onSaveSucceed(isContinue);
            } else {
                if (err.errors) {
                    displayError(err.errors);
                    setCurrentTabIndex(TAB_SCREENS.INFO);
                } else Notify(NOTIFY.ERROR, "Lỗi", err.data);
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setCurrentTabIndex(TAB_SCREENS.INFO);
        }
    }

    /** handle thay đổi tab */
    function _handleChangedTab(tab) {
        setCurrentTabIndex(tab);
        if (tab == TAB_SCREENS.PRODUCTS)
            setTimeout(() => {
                if (document.getElementById("txtSearch")) document.getElementById("txtSearch").focus();
            }, 500);
    }

    /** handle thay đổi thông tin voucher trong voucher brand */
    function _handleOnChangedd(name, value) {
        let voucherBrand = { ...voucher };
        let vc = voucherBrand.voucher;
        vc[name] = value;
        setVoucher(voucherBrand);
    }

    /** lấy thông tin voucher by id */
    async function _getVoucherById(voucherId, productId) {
        let [err, data] = await VoucherServices.GetVoucherBrandProductById(voucherId, productId);
        if (!err && data) {
            setVoucher(data);
        } else {
            setVoucher(new VoucherBrandModel());
        }
    }

    const _getListProduct = async (paging) => {
        const [err, data] = await ProductServices.GetProducts(paging);
        if (!err && data) {
            const options = data.listObjects.map((product) => ({
                value: product.id,
                label: product.name,
                ...product,
            }));
            setListProductOption(options);
        } else {
            setListProductOption([]);
        }
    };

    async function _getVoucherOptions(paging) {
        let [err, data] = await VoucherServices.GetVoucherBrandByPaging(paging);
        if (!err && data) {
            let vouchers = data.listObjects;
            let options = vouchers.map((item, idx) => {
                return {
                    value: item.id,
                    text: item.code,
                    label: item.code,
                };
            });
            setVoucherOptions(options);
        } else {
            setVoucherOptions([]);
        }
    }

    /**render */
    const TabHeader = useMemo(() => (
        <div className="p-b-10">
            <ul className="nav nav-tabs tabs-bordered nav-justified">
                <li className="nav-item">
                    <a className={currentTabIndex == TAB_SCREENS.INFO ? "nav-link h-100 active" : "nav-link h-100 btn-light"} onClick={() => _handleChangedTab(TAB_SCREENS.INFO)}>
                        <span className="d-none d-sm-block">Thông tin Voucher</span>
                    </a>
                </li>
            </ul>
        </div>
    ));
    function _renderInfo() {
        return (
            <div className="col-md-12 card-box p-3">
                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="code" className="required">
                                Voucher:
                            </label>
                            <TFUSelect options={voucherOptions} placeholder="--Chọn Voucher--" value={voucher.voucherId} onChanged={(value) => setVoucher({ ...voucher, voucherId: value })} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="code" className="required">
                                Sản phẩm:
                            </label>
                            <TFUSelect options={listProductOption} placeholder="--Chọn Sản phẩm--" value={voucher.productId} onChanged={(value) => setVoucher({ ...voucher, productId: value })} />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá mặc định khi thu thập Voucher" target="discountValueDefault">
                                <label id="discountValueDefault" htmlFor="discountValueDefault">
                                    Số tiền giảm khi thu thập Voucher mặc định
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="discountValueDefault"
                                value={voucher.discountValueDefault}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueDefault: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel1">
                                <label id="maximumLoyaltyLevel1" htmlFor="maximumLoyaltyLevel1">
                                    Tiền bản quyền Creator Mức 1
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel1"
                                value={voucher.maximumLoyaltyLevel1}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel1: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel1">
                                <label id="discountValueLevel1" htmlFor="discountValueLevel1">
                                    Số tiền giảm trên mỗi sản phẩm Mức 1
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel1"
                                value={voucher.discountValueLevel1}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel1: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel2">
                                <label id="maximumLoyaltyLevel2" htmlFor="maximumLoyaltyLevel2">
                                    Tiền bản quyền Creator Mức 2
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel2"
                                value={voucher.maximumLoyaltyLevel2}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel2: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel2">
                                <label id="discountValueLevel2" htmlFor="discountValueLevel2">
                                    Số tiền giảm trên mỗi sản phẩm Mức 2
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel2"
                                value={voucher.discountValueLevel2}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel2: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel3">
                                <label id="maximumLoyaltyLevel3" htmlFor="maximumLoyaltyLevel3">
                                    Tiền bản quyền Creator Mức 3
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel3"
                                value={voucher.maximumLoyaltyLevel3}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel3: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel3">
                                <label id="discountValueLevel3" htmlFor="discountValueLevel3">
                                    Số tiền giảm trên mỗi sản phẩm Mức 3
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel3"
                                value={voucher.discountValueLevel3}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel3: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel4">
                                <label id="maximumLoyaltyLevel4" htmlFor="maximumLoyaltyLevel4">
                                    Tiền bản quyền Creator Mức 4
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel4"
                                value={voucher.maximumLoyaltyLevel4}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel4: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel4">
                                <label id="discountValueLevel4" htmlFor="discountValueLevel4">
                                    Số tiền giảm trên mỗi sản phẩm Mức 4
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel4"
                                value={voucher.discountValueLevel4}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel4: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel5">
                                <label id="maximumLoyaltyLevel5" htmlFor="maximumLoyaltyLevel5">
                                    Tiền bản quyền Creator Mức 5
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel5"
                                value={voucher.maximumLoyaltyLevel5}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel5: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel5">
                                <label id="discountValueLevel5" htmlFor="discountValueLevel5">
                                    Số tiền giảm trên mỗi sản phẩm Mức 5
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                required
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel5"
                                value={voucher.discountValueLevel5}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel5: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel6">
                                <label id="maximumLoyaltyLevel6" htmlFor="maximumLoyaltyLevel6">
                                    Tiền bản quyền Creator Mức 6
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel6"
                                value={voucher.maximumLoyaltyLevel6}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel6: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel6">
                                <label id="discountValueLevel6" htmlFor="discountValueLevel6">
                                    Số tiền giảm trên mỗi sản phẩm Mức 6
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel6"
                                value={voucher.discountValueLevel6}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel6: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel7">
                                <label id="maximumLoyaltyLevel7" htmlFor="maximumLoyaltyLevel7">
                                    Tiền bản quyền Creator Mức 7
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel7"
                                value={voucher.maximumLoyaltyLevel7}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel7: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel7">
                                <label id="discountValueLevel7" htmlFor="discountValueLevel7">
                                    Số tiền giảm trên mỗi sản phẩm Mức 7
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel7"
                                value={voucher.discountValueLevel7}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel7: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel8">
                                <label id="maximumLoyaltyLevel8" htmlFor="maximumLoyaltyLevel8">
                                    Tiền bản quyền Creator Mức 8
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel8"
                                value={voucher.maximumLoyaltyLevel8}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel8: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel8">
                                <label id="discountValueLevel8" htmlFor="discountValueLevel8">
                                    Số tiền giảm trên mỗi sản phẩm Mức 8
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel8"
                                value={voucher.discountValueLevel8}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel8: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel9">
                                <label id="maximumLoyaltyLevel9" htmlFor="maximumLoyaltyLevel9">
                                    Tiền bản quyền Creator Mức 9
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel9"
                                value={voucher.maximumLoyaltyLevel9}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel9: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel9">
                                <label id="discountValueLevel9" htmlFor="discountValueLevel9">
                                    Số tiền giảm trên mỗi sản phẩm Mức 9
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel9"
                                value={voucher.discountValueLevel9}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel9: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Tiền bản quyền của Creator phải cài đặt nhỏ hơn số này" target="maximumLoyaltyLevel10">
                                <label id="maximumLoyaltyLevel10" htmlFor="maximumLoyaltyLevel10">
                                    Tiền bản quyền Creator Mức 10
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="maximumLoyaltyLevel10"
                                value={voucher.maximumLoyaltyLevel10}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, maximumLoyaltyLevel10: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TFUTooltip content="Giá trị giảm giá trên mỗi sản phẩm mức 1" target="discountValueLevel10">
                                <label id="discountValueLevel10" htmlFor="discountValueLevel10">
                                    Số tiền giảm trên mỗi sản phẩm Mức 10
                                </label>
                            </TFUTooltip>
                            <TextNumber
                                ref={addRef}
                                className="form-control"
                                name="discountValueLevel10"
                                value={voucher.discountValueLevel10}
                                onChanged={(name, value) => {
                                    setVoucher({ ...voucher, discountValueLevel10: value });
                                }}
                                suffix="đồng"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>HDSD:</label>
                    <div className="sub-header">
                        <table className="table table-bordered m-b-0">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Giá áo chưa in</td>
                                    <td>Tiền bản quyền áp dụng</td>
                                    <td>Số tiền giảm trên mỗi sản phẩm khi Collect Voucher</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Gốc</td>
                                    <td>219.000 đồng</td>
                                    <td>Khi chưa Collect Voucher</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Mặc định</td>
                                    <td>199.000 đồng</td>
                                    <td>Không giới hạn mức bản quyền</td>
                                    <td>20.000 đồng</td>
                                </tr>
                                <tr>
                                    <td>Mức 1</td>
                                    <td>179.000 đồng</td>
                                    <td>Nhỏ hơn 200.000 đồng</td>
                                    <td>40.000 đồng</td>
                                </tr>
                                <tr>
                                    <td>Mức 2</td>
                                    <td>149.000 đồng</td>
                                    <td>Nhỏ hơn 30.000 đồng</td>
                                    <td>70.000 đồng</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <CommonModal
            show={props.isShowModal}
            isBusy={isLoading}
            busyMsg={msg}
            size="lg"
            title={voucher.id == 0 ? "Thêm mới" : "Cập nhật"}
            customButton={
                <React.Fragment>
                    <button className="btn btn-custom btn-sm" onClick={() => _handleSave(false)}>
                        <i className="fa fa-save m-r-5" /> Lưu
                    </button>
                    {voucher.id > 0 ? null : (
                        <button className="btn btn-outline-primary btn-sm" onClick={() => _handleSave(true)}>
                            <i className="fa fa-save m-r-5" /> Lưu & tiếp tục
                        </button>
                    )}
                </React.Fragment>
            }
            onClose={() => props.onClose()}
        >
            {TabHeader}
            <div className={currentTabIndex == TAB_SCREENS.INFO ? "" : "hide"}>{_renderInfo()}</div>
        </CommonModal>
    );
}
