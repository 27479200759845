import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../based/Common";
import { NOTIFY, PAYMENT_METHOD, SHIPPING_SERVICE_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import OrderServices from "../based/services/OrderServices";

function LogisticCreatePage(props) {
    const { id } = props.match.params;
    const [deliveryModel, setDeliveryModel] = useState();
    const history = useHistory();

    console.log("deliveryModel", deliveryModel);

    useEffect(() => {
        if (id) fetchData(id);
    }, [id]);

    const fetchData = async (shippingOrderNumber) => {
        const [err, data] = await OrderServices.GetOrderGrabDeliveryInfoById(shippingOrderNumber);
        if (!err && data) {
            setDeliveryModel(data);
        } else {
            setDeliveryModel(null);
        }
    };

    const CODAmount = useMemo(() => {
        if (deliveryModel && deliveryModel.order) {
            let redundantPrice = deliveryModel.order.finallyPrice - (deliveryModel.order.transferPartial || 0);
            if (redundantPrice < 0) redundantPrice = 0;
            if (deliveryModel.order.paymentMethod == PAYMENT_METHOD.COD || deliveryModel.order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) {
                return redundantPrice;
            }
            return 0;
        }

        return 0;
    }, [deliveryModel]);

    const totalQuantity = useMemo(() => {
        if (deliveryModel && deliveryModel.products) {
            return deliveryModel.products.reduce((total, item) => total + item.quantity, 0);
        }
        return 0;
    }, [deliveryModel]);

    const distance = useMemo(() => {
        if (deliveryModel && deliveryModel.quoteModels) {
            let first = deliveryModel.quoteModels[0];
            return first ? first.distance / 1000 : 0;
        }
        return 0;
    }, [deliveryModel]);

    const createOrder = async () => {
        if (!deliveryModel || !deliveryModel.order || !deliveryModel.quoteModels) return;
        let orderId = deliveryModel.order.id;
        let quoteActive = deliveryModel.quoteModels.find((x) => x.isActive);
        if (!quoteActive) return;

        let quoteServiceCodeInt = 0;
        Object.entries(SHIPPING_SERVICE_TYPE).map(([key, value]) => {
            if (key == quoteActive.serviceCode) quoteServiceCodeInt = value;
        });

        const [err, data] = await OrderServices.PrintShippingLabel(orderId, quoteActive.id, {}, quoteServiceCodeInt);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thông báo", "Tạo đơn hàng thành công");
            history.push("/logistic");
        } else {
            Notify(NOTIFY.ERROR, "Thông báo", "Tạo đơn hàng thất bại");
        }
    };

    const totalWeight = useMemo(() => {
        if (deliveryModel && deliveryModel.products) {
            var totalWeight = deliveryModel.products.reduce((total, item) => total + item.shippingWeight, 0);
            return totalWeight / 100;
        }
        return 0;
    }, [deliveryModel]);

    if (!deliveryModel) return null;
    return (
        <Wrapper>
            {deliveryModel.order && (
                <div className="header">
                    <div className="header-left">{deliveryModel.order.id}</div>
                    <div className="header-right">Mã đơn Ranus: {deliveryModel.order.orderNumber}</div>
                </div>
            )}
            <div className="section-info">
                {deliveryModel.warehousesModel && (
                    <div className="sender">
                        <div className="sender-header">
                            <div className="sender-header-left">Điểm lấy hàng</div>
                            <div className="sender-header-right">
                                <div className="icon">
                                    <GoRightIcon />
                                </div>
                            </div>
                        </div>
                        <div className="sender-body">
                            <p>
                                {deliveryModel.warehousesModel.name} - {deliveryModel.warehousesModel.phoneNumber}
                            </p>
                            <p>{deliveryModel.warehousesModel.fullAddress}</p>
                        </div>
                    </div>
                )}
                {deliveryModel.shippingAddress && (
                    <div className="receiver">
                        <div className="receiver-header">
                            <div className="receiver-header-left">Điểm giao hàng</div>
                            <div className="receiver-header-right">
                                <div className="right-detail">{distance > 0 ? `Cách ${distance} Km` : ""}</div>
                                <div className="icon">
                                    <GoRightIcon />
                                </div>
                            </div>
                        </div>
                        <div className="receiver-body">
                            <p>
                                {deliveryModel.shippingAddress.userName} - {deliveryModel.shippingAddress.phoneNumber}
                            </p>
                            <p>{deliveryModel.shippingAddress.fullAddress}</p>
                        </div>
                    </div>
                )}

                <div className="booking-type">
                    <div className="booking-left">
                        <div className="booking-type-header">COD</div>
                        <div className="booking-type-body">{Common.formatCurrency(CODAmount)}</div>
                    </div>
                    <div className="booking-middle">
                        <div className="booking-type-header">Size</div>
                        <div className="booking-type-body">S</div>
                    </div>
                    <div className="booking-right">
                        <div className="booking-type-header">Cân nặng</div>
                        <div className="booking-type-body">{totalWeight} Kg</div>
                    </div>
                </div>
            </div>

            <div className="section-booking">
                <div className="booking-main">Chọn phương thức vận chuyển *</div>

                <div className="method-type">
                    {deliveryModel.quoteModels &&
                        deliveryModel.quoteModels.map((item, index) => {
                            let length = deliveryModel.quoteModels.length;
                            let className = "";
                            if (index == 0) className = "method-left";
                            else if (index == length - 1) className = "method-right";
                            else className = "method-middle";
                            return (
                                <div
                                    className={`${className} ${item.isActive ? "active" : ""}`}
                                    style={{
                                        width: `${100 / length}%`,
                                    }}
                                    key={index}
                                    onClick={(e) => {
                                        let newQuoteModels = deliveryModel.quoteModels.map((x) => ({ ...x, isActive: false }));
                                        newQuoteModels[index].isActive = true;
                                        setDeliveryModel({ ...deliveryModel, quoteModels: newQuoteModels });
                                    }}
                                >
                                    <div className="method-type-header">{item.serviceName}</div>
                                    <div className="method-type-body">{Common.formatCurrency(item.shippingFee)}</div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="order-product">
                <div className="order-product-overview">
                    Chi tiết đơn hàng ({totalQuantity}): {deliveryModel.order ? Common.formatCurrency(deliveryModel.order.finallyPrice) : 0}
                </div>
                <table className="table table-border">
                    <thead>
                        <tr>
                            <th>Sản phẩm</th>
                            <th>Số lượng</th>
                            <th>Tổng tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveryModel.products.map((item, index) => {
                            let sizeItem = item.attributeValueLightModels ? item.attributeValueLightModels.find((x) => x.code == "Size") : null;
                            const size = sizeItem ? sizeItem.value : "";
                            return (
                                <tr key={index}>
                                    <td>
                                        <span className="main">{item.name}</span>
                                        <span className="sub">
                                            Màu {item.colorDisplayName}, Size {size}
                                        </span>
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>{Common.formatCurrency(0)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="bottom-bar">
                <div
                    className="create-button"
                    onClick={(e) => {
                        createOrder();
                    }}
                >
                    <span>Đặt xe</span>
                </div>
            </div>
        </Wrapper>
    );
}

export default LogisticCreatePage;

const Wrapper = styled.div`
    height: 100vh;
    padding: 20px 16px 80px 16px;
    overflow: auto;

    .header {
        display: flex;
    }

    .header-right {
        margin-left: auto;
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    .section-info {
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
        margin: 12px 0;
    }

    .sender-header {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
    }

    .sender-header-right {
        display: flex;
        margin-left: auto;
    }

    .sender-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    p {
        margin: 0 !important;
    }

    .sender-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .sender {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header {
        display: flex;
        align-items: center;
    }

    .receiver-header-right {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .receiver {
        padding: 8px 12px;
        border-bottom: 1px solid #d9d9d9;
    }

    .receiver-header-right .icon {
        margin-left: 8px;
    }

    .right-detail {
        color: #28b259;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .receiver-header-left {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .receiver-body p {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .booking-type {
        display: flex;
    }

    .booking-left {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border: 1px solid #d9d9d9;
        background: white;
        border-radius: 0 0 0 12px;
    }

    .booking-middle {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border: 1px solid #d9d9d9;
        background: #f6f6f6;
    }

    .booking-right {
        width: 33.33%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 18px;
        border: 1px solid #d9d9d9;
        background: #f6f6f6;
        border-radius: 0 0 12px 0;
    }

    .booking-left-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        display: flex;
    }

    .booking-left-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        display: flex;
    }

    .booking-middle-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-middle-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-right-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
    }

    .booking-right-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
    }

    .booking-type-header {
        color: #a0a0a0;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .booking-type-body {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .booking-left .booking-type-body {
        color: #28b259;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .order-product-overview {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 8px;
    }

    thead {
        border-radius: 10px 0px 0px 0px;
        border: 1px solid #ededed;
        background: #878787;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
    }

    td span.main {
        display: block;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    td span.sub {
        display: block;
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-align: left;
    }

    tr td {
        vertical-align: middle !important;
        text-align: center;
        color: #000;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    table.table.table-border {
        background: white;
    }

    .bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #d9d9d9, 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    }

    .create-button {
        border-radius: 12px;
        background: #28b259;
        text-align: center;
        padding: 14px;
    }

    .create-button span {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .booking-main {
        color: #000;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .method-type {
        display: flex;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .method-left {
        width: 33.33%;
        text-align: center;
        padding: 12px 24px;
        border-radius: 12px 0 0 12px;
    }

    .active {
        background: #28b259;
    }

    .method-middle {
        width: 33.33%;
        text-align: center;
        padding: 12px 24px;
        border: 1px solid #d9d9d9;
    }

    .method-right {
        width: 33.33%;
        text-align: center;
        padding: 12px 24px;
        border-radius: 0 12px 12px 0;
    }

    .method-type-header {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
    }

    .method-type-body {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-top: 4px;
    }

    .method-type .active div {
        color: white !important;
    }

    .order-product {
        margin-top: 24px;
    }
`;

const GoRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
            <path d="M1 1L5 5L1 9" stroke="black" strokeWidth="1.5" />
        </svg>
    );
};
