import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { NOTIFY } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import CheckBox from "../based/inputs/CheckBox";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import SetProductServices from "../based/services/SetProductServices";
import ConfigSetProduct from "./ConfigSetProduct";
import DetailSetProduct from "./DetailSetProduct";

const AppAgencyType = {
    App: 1,
    Agency: 2,
};
const SetProductModel = {
    id: 0,
    title: "",
    setAvatar: "",
    productVariants: [],
    setType: AppAgencyType.App,
};

export default function SetProductManagement(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [paging, setPaging] = useState(Common.Paging);
    const [datas, setDatas] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [config, setConfig] = useState(SetProductModel);
    useEffect(() => {
        getDatas(Common.Paging);
    }, []);
    async function getDatas(newPaging) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await SetProductServices.GetPaging(newPaging);
        if (!error && data) {
            setDatas(data.listObjects);
            setPaging(data.paging);
            setIsLoading(false);
            setMsg(null);
        } else {
            setDatas([]);
            setIsLoading(false);
            setMsg(null);
        }
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th width={40} className="text-center">
                        <CheckBox checked={selectedAll} name="select_all" id="select_all" label=" " onCheckedChange={(name, value) => _handleCheckedAll(value)} />
                    </th>
                    <th width="120" className="text-center">
                        Ảnh
                    </th>
                    <th>Tên Set sản phẩm</th>
                    <th width="200" className="text-center">
                        Số lượng sản phẩm
                    </th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, k) => {
                        let avatar = item.setAvatar ? item.setAvatar : Request.CdnURL() + "/images_system/no-image.png";
                        return (
                            <tr key={k}>
                                <td className="text-center vertical-align-middle">
                                    <CheckBox checked={item.checked} name={k.toString()} id={k.toString()} label=" " onCheckedChange={(name, value) => _handleChecked(name, value)} />
                                </td>
                                <td>
                                    <a className="iconImageDefault full-width">
                                        <img src={avatar} className="variant-avatar" />
                                    </a>
                                </td>
                                <td className="text-left vertical-align-middle">
                                    <span>{item.title}</span>
                                </td>
                                <td className="text-center vertical-align-middle">
                                    <span>{item.totalVariants}</span>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan="5" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                )}
            </tbody>
        );
    }
    function _renderAction() {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.checked);
            if (dataChecked && dataChecked.length == 1) isEnabled = true;
        }
        return (
            <div className="p-y-10 box__actions">
                <div className="form-group full-width">
                    <button className="btn btn-custom btn-sm m-r-5" onClick={_handleAdd}>
                        <i className="fa fa-plus m-r-5" />
                        Tạo mới
                    </button>
                    {datas && datas.length > 0 && (
                        <React.Fragment>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleEdit}>
                                <i className="fa fa-edit m-r-5" /> Sửa
                            </button>
                            <button className={isEnabled ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={_handleDetail}>
                                <i className="fa fa-eye m-r-5" /> Chi tiết
                            </button>
                            <button className={dataChecked && dataChecked.length > 0 ? "btn btn-danger  btn-sm m-r-5" : "btn btn-danger  btn-sm m-r-5 disable"} onClick={_handleDelete}>
                                <i className="fa fa-remove m-r-5" />
                                Xóa
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
    function _handleAdd() {
        setConfig(SetProductModel);
        setIsShowModal(true);
    }
    async function _handleEdit() {
        var dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await SetProductServices.GetByIdForUpdate(dataChecked[0].id);
            if (!error && data) {
                data.isRunServices = false;
                setConfig(data);
                setIsShowModal(true);
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình lấy thông tin.");
            }
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn một thông tin trước khi sửa");
    }
    async function _handleDetail() {
        var dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length == 1) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await SetProductServices.GetByIdForUpdate(dataChecked[0].id);
            if (!error && data) {
                setConfig(data);
                setShowDetail(true);
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình lấy thông tin.");
            }
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn một thông tin trước khi xem");
    }
    function _handleDelete() {
        var dataChecked = datas.filter((x) => x.checked);
        if (dataChecked && dataChecked.length > 0) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                return new Promise(async (resolve, reject) => {
                    setIsLoading(true);
                    setMsg("Đang tải dữ liệu...");
                    let ids = [];
                    dataChecked.map((x) => ids.push(x.id));
                    let [error, data] = await SetProductServices.DeleteSets(ids);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                        getDatas(paging);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình xóa.");
                    }
                });
            });
        } else Notify(NOTIFY.WARNING, "Cảnh báo", "Vui lòng chọn thông tin trước khi xóa");
    }

    function _handleCheckedAll(value) {
        setSelectedAll(value);
        let datasUpdate = [...datas];
        datasUpdate.map((item) => (item.checked = value));
        setDatas(datasUpdate);
    }
    function _handleChecked(index, value) {
        let updateData = [...datas];
        let data = updateData[index];
        data.checked = !data.checked;
        setDatas(updateData);
        setSelectedAll(updateData.filter((x) => x.checked).length == datas.length);
    }
    return (
        <React.Fragment>
            <Breadcrumbs currentPage="Set Sản phẩm" />
            <Loading show={isLoading} msg={msg} />
            {isShowModal && (
                <ConfigSetProduct
                    isShowModal={isShowModal}
                    onClose={() => setIsShowModal(false)}
                    onSuccess={() => {
                        setIsShowModal(false);
                        getDatas(paging);
                    }}
                    config={config}
                />
            )}
            {showDetail && <DetailSetProduct isShowModal={showDetail} onClose={() => setShowDetail(false)} config={config} />}
            <CommonTable data={datas} paging={paging} renderHeader={_renderHeader} renderBody={_renderBody} renderAction={_renderAction} onFilter={getDatas} searchBasic />
        </React.Fragment>
    );
}
