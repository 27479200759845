import React, { useContext, useEffect } from "react";
import { NOTIFY, POSITION_LEVEL, VARIANT_IMAGE_TYPE } from "../based/Constants";
import { Notify } from "../based/Notify";
import PrintServices from "../based/services/PrintServices";
import ProductServices from "../based/services/ProductServices";
import { ConfigAttributeModalContext, PrintBrandStampModel } from "../context/ConfigAttributeModalContext";
import { ProductVariantContext } from "../context/ProductVariantContext";
import { ImageModel } from "../models/BaseProductModel";
import { PrintFrameConfigModel } from "../models/PrintFrameConfigModel";
import VariantFrameConfigModel from "../models/VariantFrameConfigModel";
import VariantFrameConfigItem from "./components/VariantFrameConfigItem";

const ImageTypeOptions = [
    { value: VARIANT_IMAGE_TYPE.VariantFront, label: "Mặt trước", text: "Mặt trước" },
    { value: VARIANT_IMAGE_TYPE.VariantBack, label: "Mặt sau", text: "Mặt sau" },
];

const ImageTypeInclude = [VARIANT_IMAGE_TYPE.VariantFront, VARIANT_IMAGE_TYPE.VariantBack];

function SectionVariantFrameConfig(props) {
    const { editingVariant, setEditingVariant, addRef } = useContext(ProductVariantContext);
    const { printFrame, setPrintFrame, attributeId, isReadOnly } = useContext(ConfigAttributeModalContext);

    useEffect(() => {
        if (attributeId > 0) getData(attributeId);
        else setPrintFrame([new PrintFrameConfigModel()]);
    }, [attributeId]);

    //lấy thông tin frameconfig
    const getData = async (attributeId) => {
        let [err, data] = await PrintServices.GetFrameConfigsByAttributeId(attributeId);
        if (!err) setPrintFrame(data);
        else setPrintFrame([new PrintFrameConfigModel()]);
    };

    //setup thông tin config
    const _handleEditConfig = (name, value, index) => {
        let imageName = ["url", "imageType"];
        let editConfig = { ...editingVariant };
        let image = editConfig.listImage;
        let item = image[index];

        //nếu là url và imagetype thì update image
        if (imageName.includes(name)) {
            item[name] = value;

            //nếu name là image type, tiến hành lấy config chuẩn
            if (name == "imageType") {
                //lấy ra thuộc tính size
                let attribute = editConfig.attributes;
                let sizeAttribute = attribute ? attribute.find((x) => x.isPrintFrameConfig) : null;
                if (!sizeAttribute) return;
                let frameConfig = printFrame.find((x) => x.attributeValueName == sizeAttribute.value);
                if (!frameConfig) Notify(NOTIFY.INFO, NOTIFY.INFO, "Hiện tại chưa config kích thước thuộc tính size");

                //set up print frame
                let min = 0;
                let config = item.prinT_VariantFrameConfig;
                if (frameConfig) {
                    if (value == VARIANT_IMAGE_TYPE.VariantFront) min = frameConfig.frontMin;
                    else min = frameConfig.backMin;
                }
                config.lengthFrameMin = min;

                //set up logo frame
                if (value == VARIANT_IMAGE_TYPE.VariantFront) {
                    let positionA = new PrintBrandStampModel();
                    positionA.positionLevel = POSITION_LEVEL.A;
                    positionA.heightCollar = frameConfig.logoHeightCollar;
                    positionA.widthCollar = frameConfig.logoWidthCollar;

                    let positionB = new PrintBrandStampModel();
                    positionB.positionLevel = POSITION_LEVEL.B;
                    positionB.heightCollar = frameConfig.logoHeightCollar;
                    positionB.widthCollar = frameConfig.logoWidthCollar;
                    item.prinT_BrandStampConfigs = [positionA, positionB];
                }
                if (value == VARIANT_IMAGE_TYPE.VariantBack) {
                    let positionC = new PrintBrandStampModel();
                    positionC.positionLevel = POSITION_LEVEL.C;
                    positionC.heightCollar = frameConfig.logoHeightBackCollar;
                    item.prinT_BrandStampConfigs = [positionC];
                }
            }
        }

        //nếu name là khác thì update vào config
        else {
            let config = item.prinT_VariantFrameConfig;
            config[name] = value;
        }

        setEditingVariant(editConfig);
    };

    //add hình ảnh và thông tin config
    const _handleAdd = () => {
        let editConfig = { ...editingVariant };
        let image = editConfig.listImage;

        //lấy ra thuộc tính size
        let attribute = editConfig.attributes;
        let sizeAttribute = attribute ? attribute.find((x) => x.isPrintFrameConfig) : null;
        if (!sizeAttribute) return;
        let frameConfig = printFrame.find((x) => x.attributeValueName == sizeAttribute.value);
        if (!frameConfig) Notify(NOTIFY.INFO, NOTIFY.INFO, "Hiện tại chưa config kích thước thuộc tính size");

        //gán giá trị frame config vào image
        let newItem = new ImageModel();
        let variantFrame = new VariantFrameConfigModel();
        let logoFrame = [];
        newItem.imageType = VARIANT_IMAGE_TYPE.VariantFront;
        if (frameConfig) {
            //gán giá trị frame
            variantFrame.width = frameConfig.widthFrame;
            variantFrame.height = frameConfig.heightFrame;
            variantFrame.lengthFrameMin = frameConfig.frontMin;

            //gán giá trị logo, mặt trước, 2 vị trí
            let positionA = new PrintBrandStampModel();
            positionA.positionLevel = POSITION_LEVEL.A;
            positionA.heightCollar = frameConfig.logoHeightCollar;
            positionA.widthCollar = frameConfig.logoWidthCollar;
            logoFrame.push(positionA);

            let positionB = new PrintBrandStampModel();
            positionB.positionLevel = POSITION_LEVEL.B;
            positionB.heightCollar = frameConfig.logoHeightCollar;
            positionB.widthCollar = frameConfig.logoWidthCollar;
            logoFrame.push(positionB);
        }

        newItem.prinT_VariantFrameConfig = variantFrame;
        newItem.prinT_BrandStampConfigs = logoFrame;
        image.push(newItem);
        setEditingVariant(editConfig);
    };

    //xóa hình ảnh và thông tin config
    const _handleRemove = (index) => {
        let editConfig = { ...editingVariant };
        let image = editConfig.listImage;
        image.splice(index, 1);
        setEditingVariant(editConfig);
    };

    //validate ảnh
    const _handleValidate = async (index) => {
        let editConfig = { ...editingVariant };
        let listImage = editConfig.listImage;
        let image = listImage[index];

        let [err, data] = await ProductServices.ValidateImageMockup(image);
        if (!err && data) {
            image.mockup = data;
            setEditingVariant(editConfig);
        } else {
            Notify(NOTIFY.INFO, NOTIFY.INFO, "Kiểm tra thất bại. Xin vui lòng kiểm tra lại thông số");
        }
    };

    const _handleImageTemplate = (fileName, index) => {
        //template: 1A_Front_15_25_35
        //template: 1B_Back_25_35_45
        var split = fileName.split("_");
        if (split && split.length >= 3) {
            //config hiện tại
            let editConfig = { ...editingVariant };
            let attribute = editConfig.attributes;
            let sizeAttribute = attribute ? attribute.find((x) => x.isPrintFrameConfig) : null;
            if (!sizeAttribute) return;
            let frameConfig = printFrame.find((x) => x.attributeValueName == sizeAttribute.value);
            if (!frameConfig) Notify(NOTIFY.INFO, NOTIFY.INFO, "Hiện tại chưa config kích thước thuộc tính size");
            if (!frameConfig) return;

            //loại hình ảnh
            let imageType = split[1];
            //các kích thước còn lại
            if (imageType == "Front" && split.length >= 4) {
                _handleEditConfig("axisX", +split[2], index);
                _handleEditConfig("collarY", +split[3], index);
                _handleEditConfig("frameMinY", +split[4], index);
                _handleEditConfig("imageType", VARIANT_IMAGE_TYPE.VariantFront, index);

                //gán giá trị logo hiện tại
                let positionA = new PrintBrandStampModel();
                positionA.positionLevel = POSITION_LEVEL.A;
                positionA.heightCollar = frameConfig.logoHeightCollar;
                positionA.widthCollar = frameConfig.logoWidthCollar;
                let positionB = new PrintBrandStampModel();
                positionB.positionLevel = POSITION_LEVEL.B;
                positionB.heightCollar = frameConfig.logoHeightCollar;
                positionB.widthCollar = frameConfig.logoWidthCollar;
                handlePosition(index, [positionA, positionB]);
            }
            if (imageType == "Back" && split.length >= 4) {
                _handleEditConfig("axisX", +split[2], index);
                _handleEditConfig("collarY", +split[3], index);
                _handleEditConfig("frameMinY", +split[4], index);
                _handleEditConfig("imageType", VARIANT_IMAGE_TYPE.VariantBack, index);

                //gán giá trị logo hiện tại
                let positionC = new PrintBrandStampModel();
                positionC.positionLevel = POSITION_LEVEL.C;
                positionC.heightCollar = frameConfig.logoHeightBackCollar;
                handlePosition(index, [positionC]);
            }
        }
    };

    const handlePosition = (index, printBrand) => {
        let editConfig = { ...editingVariant };
        let image = editConfig.listImage;
        let item = image[index];
        item.prinT_BrandStampConfigs = printBrand;
        setEditingVariant(editConfig);
    };

    return (
        <div className="card-box margin-bottom-10">
            <div>
                <table className="table table-bordered m-b-0">
                    <thead className="thead-light">
                        <tr>
                            <th className="cursor-pointer center" width="200">
                                Hình ảnh<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="500">
                                Mặt áo
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Kích thước ngang thực tế<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Kích thước dọc thực tế<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Cách cổ Min (Khoảng cách từ cổ áo tới khung)<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Tọa độ X (Vị trí cổ áo - Điểm số 1)<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Tọa độ Y (Vị trí cổ áo - Điểm số 1)<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Tọa độ Y (Vị trí cách điểm số 1 - Điểm số 2)<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Khoảng cách đánh dấu tiêu chuẩn, cách từ điểm số 1 đến điểm số 2<span className="required-field-icon">&nbsp;*</span>
                            </th>
                            <th className="cursor-pointer center" width="200">
                                Hình ảnh Mockup vị trí Cách cổ Min
                            </th>
                            <th className="cursor-pointer center" width="50">
                                Sử dụng hình ảnh render tại trang PDP
                            </th>
                            <th className="cursor-pointer center" width="50">
                                Sử dụng hình ảnh render tại trang Upload Thiết Kế
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {editingVariant && editingVariant.listImage
                            ? editingVariant.listImage.map((item, index) => {
                                  let config = item.prinT_VariantFrameConfig || new VariantFrameConfigModel();
                                  if (ImageTypeInclude.includes(item.imageType))
                                      return (
                                          <VariantFrameConfigItem
                                              key={index}
                                              index={index}
                                              addRef={addRef}
                                              imageType={item.imageType}
                                              imageTypeOptions={ImageTypeOptions}
                                              url={item.url}
                                              width={config.width}
                                              height={config.height}
                                              lengthFrameMin={config.lengthFrameMin}
                                              axisX={config.axisX}
                                              collarY={config.collarY}
                                              frameMinY={config.frameMinY}
                                              positionAX={config.positionAX}
                                              positionAY1={config.positionAY1}
                                              positionAY2={config.positionAY2}
                                              positionBY1={config.positionBY1}
                                              positionCY1={config.positionCY1}
                                              digitalScale={config.digitalScale}
                                              allowRenderPDP={config.allowRenderPDP}
                                              allowRenderUpload={config.allowRenderUpload}
                                              handleChange={(name, value) => _handleEditConfig(name, value, index)}
                                              handleRemove={() => _handleRemove(index)}
                                              handleValidate={() => _handleValidate(index)}
                                              mockup={item.mockup}
                                              onFileName={(fileName) => {
                                                  _handleImageTemplate(fileName, index);
                                              }}
                                          />
                                      );
                                  return null;
                              })
                            : null}
                        <tr>
                            <td colSpan="999" className="text-right">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        _handleAdd();
                                    }}
                                >
                                    Thêm cấu hình
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

SectionVariantFrameConfig.propTypes = {};

export default SectionVariantFrameConfig;
