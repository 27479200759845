import PubSub from "pubsub-js";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../based/Breadcrumbs";
import { CONSTANTS, MAINMENU, PLATFORM } from "../based/Constants";
import Loading from "../based/Loading";
import { Layout } from "../layout/Layout";
import ProductsFIFOListing from "./ProductsFIFOListing";
export default function ManageAgencyProduct(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(PLATFORM.LOTUS);

    useEffect(() => {
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, {
            mainMenu: MAINMENU.ManageProduct,
        });
        setTabIndex(tabIndex);
    }, []);

    return (
        <Layout>
            <Breadcrumbs currentPage='Sản phẩm' />
            <Loading show={isLoading} msg='Đang tải dữ liệu...' />
            {tabIndex == PLATFORM.LOTUS ? <ProductsFIFOListing history={props.history} /> : <React.Fragment></React.Fragment>}
        </Layout>
    );
}
